@import "@danishagro/shared/src/styles/helpers/all";

.serviceButtons {
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    margin: 0 rem(-8) 0 40px;
}

.serviceButton {
    @include clean-button;
    align-items: center;
    background: transparent;
    color: var(--color-grey-80);
    display: flex;
    height: rem(30);
    position: relative;
    text-decoration: none;
    width: rem(30);

    &::after {
        background: var(--color-primary-1);
        bottom: 0;
        content: "";
        height: rem(3);
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        transform: scaleX(0.9) scaleY(0.333);
        transform-origin: center bottom;
        transition: 0.15s ease-out;
        transition-property: opacity, transform;
    }

    &:not(.active):hover {
        color: var(--color-primary-1);

        &::after {
            opacity: 1;
            transform: scaleX(1) scaleY(0.333);
        }
    }

    &.user {
        font-size: rem(12);
        padding: 0 rem(8) 0 0;
        width: auto;
    }

    &.active {
        color: var(--color-primary-1);

        &::after {
            opacity: 1;
            transform: scaleX(1) scaleY(1);
        }
    }
}

.buttonIconWrapper {
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: rem(10);
    padding-right: rem(10);
    position: relative;

    .user & {
        padding: 0;
        width: rem(40);
    }
}

.icon {
    flex: 0 0 100%;
    height: rem(15);
    transition: color 0.15s ease-out;
    width: 100%;

    .serviceButton:hover & {
        color: var(--color-primary-1);
    }
}

.buttonBadge {
    bottom: 3px;
    position: absolute;
    right: -3px;

    .user & {
        right: 2px;
    }
}
