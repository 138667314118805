@import "@danishagro/shared/src/styles/helpers/all";

.productLoaderWrapper {
    position: relative;

    .productWrapper {
        transition: opacity 0.5s;
    }

    &.dimmed {
        .productWrapper {
            opacity: 0.15;
        }
    }
}

.loadingWrapper {
    align-items: center;
    background-color: rbga(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: rem(30);

    position: absolute;
    width: 100%;
    z-index: 3;
}

.spinner {
    flex: 0 0 auto;
    height: rem(60);
    padding: rem(10);
    width: rem(60);

    @include mq(sm) {
        margin-right: 20px;
    }
}
