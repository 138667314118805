@import "@danishagro/shared/src/styles/helpers/all";

.button {
    @include clean-button;
    color: var(--color-grey-60);
    display: block;
    height: 45px;
    padding: 15px;
    width: 45px;

    &.backButton {
        margin: -15px 0 -15px -15px;
    }

    &.closeButton {
        margin: -15px -15px -15px 0;
        position: absolute;
        right: 25px;
        top: 25px;
    }
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: -1px;
    width: 100%;

    h4 {
        text-align: left;
    }
}

.headerContent {
    align-items: center;
    display: flex;
    width: 85%;
}

.contentPadding {
    padding: 10px;
}

.contentPaddingLarge {
    padding: 10px 15px;
}
