@import "@danishagro/shared/src/styles/helpers/all";

.section {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    padding: 25px 15px;

    @include mq(md) {
        padding: rem(25);
    }

    & + .section {
        margin-top: rem(25);
    }
}

.header {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    justify-content: space-between;

    @include mq(md) {
        flex-direction: row;
    }
}

.headerSpacer {
    display: none;

    @include mq(md) {
        display: inline-block;
    }
}

.buttons {
    display: flex;
    gap: rem(20);
    justify-content: space-between;
    margin-top: rem(20);
}

.noteWrapper {
    @include border-radius(md);
    background-color: var(--color-grey-5);
    border: rem(1) solid var(--color-grey-20);
    padding: rem(15);
}

.noteHeading {
    color: var(--color-grey-80);
    font-size: rem(16);
    font-weight: 600;
}

.noteText {
    color: var(--color-grey-60);
    font-size: rem(12);
    font-weight: 400;
    margin-top: rem(10);
}
