:root {
    // Border radius
    --border-radius-small: 3px;
    --border-radius-medium: 5px;

    // Shadow
    --shadow-level-1: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    --shadow-level-2: 0 4px 4px rgba(0, 0, 0, 0.25);
    --shadow-level-3: 0 4px 11px 2px rgba(0, 0, 0, 0.15);
}
