@import "@danishagro/shared/src/styles/helpers/all";

.section {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    padding: 25px 15px;

    @include mq(md) {
        padding: rem(25);
    }

    & + .section {
        margin-top: rem(25);
    }
}

.header {
    display: flex;
    gap: rem(20);
    justify-content: space-between;
}

.inner {
    display: flex;
    flex-direction: column;
    gap: rem(20);
}

.buttons {
    display: flex;
    gap: rem(20);
    justify-content: space-between;
    margin-top: rem(20);
}

.paymentItem {
    @include border-radius(md);
    border: rem(1) solid var(--color-grey-20);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: rem(20);
    width: 100%;

    &::before {
        border: rem(1) solid var(--color-grey-20);
        border-radius: 100%;
        content: "";
        flex-shrink: 0;
        height: rem(15);
        margin-right: rem(10);
        transform: translateY(4px);
        width: rem(15);
    }

    &.isSelected {
        background-color: var(--color-primary-5);

        &::before {
            border: rem(4) solid var(--color-primary-1);
        }
    }
}

.paymentInner {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mq(sm) {
        display: contents;
    }
}

.paymentContent {
    display: contents;

    @include mq(sm) {
        display: block;
        flex-grow: 1;
    }

    strong {
        font-size: rem(16);
        font-weight: 600;
        line-height: rem(24);
        order: 0;
    }

    p {
        color: var(--color-grey-60);
        font-size: rem(12);
        margin-top: rem(5);
        order: 2;
    }
}

.paymentIcons {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    gap: rem(10);
}

.paymentInput {
    display: none;
}

.error {
    color: var(--color-alert);
    display: block;
    font-size: rem(14);
    padding: rem(10) 0 0 0;
}
