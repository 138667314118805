@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    padding-bottom: rem(50);
}

.header {
    padding-bottom: rem(30);
}

.favoritesList {
    display: flex;
    flex-direction: column;

    .favoriteActions {
        button {
            padding-left: 20px;
            padding-right: 20px;
        }

        .remove {
            padding-left: 10px;
            padding-right: 10px;

            &::before {
                border: none;
                content: "";
            }
        }
    }
}

.favoriteItem {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid var(--color-grey-10);
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 100px 150px;
    padding: 15px;

    @include mq(sm-max) {
        display: flex;
        flex-wrap: wrap;
    }

    &:last-child {
        border: none;
    }
}

.favoriteImage img {
    margin-right: 20px;
    object-fit: contain;
    width: 100%;
}

.favoriteDetails {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.favoriteActions {
    display: flex;
    gap: 10px;
    margin-left: auto;
}

.favoriteImage {
    max-height: 85px;
    max-width: 67px;
}

.imageLink {
    align-items: center;
    color: var(--color-grey-80);
    display: grid;
    gap: 20px;
    grid-template-columns: 70px 1fr;
    text-decoration: none;

    @include mq(sm-max) {
        width: 100%;
    }
}

.productId {
    font-size: rem(12);
    line-height: 18px;
}

.productName {
    font-size: rem(14);
}

.favoritePrice {
    margin-left: auto;
    margin-right: 20px;
    text-align: right;

    @include mq(sm-max) {
        margin-left: 15px;
        text-align: left;
    }
}

.favoritesHeader {
    background-color: var(--color-grey-10);
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    padding: 10px 30px;

    @include mq(md) {
        align-items: center;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 100px 150px;
        margin-left: -20px;
        margin-right: -20px;
        margin-top: -20px;
        padding: 10px 30px;
    }
}

.headerMain {
    align-items: center;
    display: grid;
    gap: 20px;
    grid-template-columns: 70px 1fr;
}

.headerDiv3,
.headerDiv4 {
    margin-left: auto;
}

.headerDiv3 {
    @include mq(sm-max) {
        display: none;
    }

    .headerTitle {
        margin-right: 20px;
    }
}

.headerTitle {
    font-weight: 700;
}

.headerDiv1,
.headerDiv2 {
    position: relative;
}
