@import "@danishagro/shared/src/styles/helpers/all";

.content {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    width: 100%;

    @include mq(sm-max) {
        padding-left: 7px;
        padding-right: 7px;
    }
}

.buttons {
    display: flex;
    gap: rem(20);
    width: 100%;
}

.button {
    flex: 1;
}

.inputWrapper {
    width: 100%;
}

.input {
    max-height: rem(100) !important;
    min-height: rem(100) !important;
    resize: none !important;
    width: 100%;
}

.error {
    margin-top: 15px;

    @include mq(sm) {
        margin-top: 20px;
    }
}
