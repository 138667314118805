@import "@danishagro/shared/src/styles/helpers/all";

.languageSelector {
    @include clean-button;
    position: relative;

    @include mq(xs-max) {
        background-color: var(--color-grey-5);
        margin-top: 5px;
        padding: 10px 10px;
    }

    .more {
        position: absolute;
        right: 10px;
        top: 15px;

        svg {
            width: 15px;
        }
    }
}

.currentLanguage {
    margin-left: 15px;
    position: relative;

    @include mq(xs-max) {
        display: table;
        margin-left: 0;
    }

    svg {
        border: 1px solid var(--color-grey-10);
        border-radius: 2px;
        position: relative;
        top: 2px;
        width: 20px;

        @include mq(xs-max) {
            width: 26px;
        }
    }
}

.dropdown {
    background-color: var(--color-grey-5);
    display: grid;
    position: absolute;
    right: 0;
    top: 30px;
    white-space: nowrap;
    width: max-content;
    z-index: 99;

    @include mq(xs-max) {
        display: block;
        margin-left: 0;
        padding-top: 10px;
        position: relative;
        top: 0;
        width: 100%;
    }
}

.option {
    padding: 10px 20px;
    text-align: left;

    @include mq(xs-max) {
        display: table;
        padding: 10px 0;
        position: relative;
        text-align: left;
        width: 100%;

        &:last-child {
            padding-bottom: 0;
        }
    }

    svg {
        border: 1px solid var(--color-grey-10);
        border-radius: 2px;
        margin-right: 5px;
        position: relative;
        top: 2px;
        width: 20px;

        @include mq(xs-max) {
            width: 26px;
        }
    }
}

.active {
    font-weight: 600;
    position: relative;
}

.flagMobile {
    @include mq(xs-max) {
        margin-right: 5px;
    }
}

.languageName {
    color: var(--color-grey-60);

    @include mq(xs-max) {
        position: relative;
        top: -2px;
    }

    &:hover {
        color: var(--color-black);
    }

    &.active {
        color: var(--color-grey-90);
    }
}
