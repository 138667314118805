@import "@danishagro/shared/src/styles/helpers/all";

.desktopMessage {
    align-items: center;
    background-color: var(--color-primary-4);
    border-bottom: 1px solid var(--color-white);
    height: 25px;
    width: 100%;

    &:last-of-type {
        border-bottom: none;
    }
}

.desktopMessageInfo {
    align-items: center;
    color: var(--color-grey-80);
    display: flex;
    font-size: rem(12);
    justify-content: flex-end;
    position: relative;
    text-align: right;
    top: 4px;
    width: 100%;
}

.messageTitle {
    flex-shrink: 0;
    font-weight: 600;
}

.messageText {
    flex-shrink: 1;
    margin-left: 5px;
    max-width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    p {
        display: contents;
    }

    a {
        color: var(--color-action);
        text-decoration: underline;
    }
}

.isOverflowing {
    padding-right: 20px;
}

.messageTextModal {
    display: block;
    margin-bottom: 30px;
    text-align: center;

    @include mq(sm) {
        text-align: left;
    }
}

.closeModalBtn {
    display: block;
    margin: auto;
}

.readMoreContainer {
    color: var(--color-primary-1);
    cursor: pointer;
    display: flex;

    span {
        width: max-content;
    }

    &:hover {
        color: var(--color-black);

        span {
            text-decoration: underline;
        }
    }
}

.readMoreIcon {
    margin-right: 5px;
    position: relative;
    top: 2px;

    svg {
        width: 12px;
    }
}
