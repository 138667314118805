.select {
    position: relative;
    z-index: 10;
}

.valueContainerWrapper {
    align-items: center;
    display: flex;
}

.valueContainerIcon {
    color: currentcolor;
    flex-shrink: 0;
    height: 100%;
    pointer-events: none;
    width: rem(17);
}

.valueContainerLabel {
    margin-left: rem(10);
    text-indent: rem(-1);
    width: 100%;
}
