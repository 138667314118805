@import "@danishagro/shared/src/styles/helpers/all";

.tabBar {
    position: relative;
}

.listWrapper {
    display: flex;
    -ms-overflow-style: none; /* IE and Edge */
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera */
    }

    @include mq(sm-max) {
        display: flex;
        -ms-overflow-style: none; /* IE and Edge */
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory; /* Enable snapping along the x-axis */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera */
        }
    }
}

.list {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;

    li {
        &:not(:first-child) {
            @include mq(md) {
                margin-left: 20px;
            }
        }

        @include mq(sm-max) {
            scroll-snap-align: start; /* Align children at the start of the snap container */

            &:not(:first-child) {
                @include mq(md) {
                    margin-left: 20px;
                }
            }
        }
    }
}

.item {
    @include clean-button;
    color: var(--tab-text-color, var(--color-grey-80));
    font-size: rem(14);
    padding: 0 5px 13px 5px;
    position: relative;
    white-space: nowrap;
    z-index: 1;

    @include mq(md) {
        font-size: rem(16);
    }

    &::before {
        content: attr(title);
        display: block;
        font-weight: bold;
        overflow: hidden;
        visibility: hidden;
    }

    // Line below
    &::after {
        background: var(--tab-active-line-color, var(--color-primary-1));
        bottom: 0;
        content: "";
        display: flex;
        height: 3px;
        left: 0;
        pointer-events: none;
        position: absolute;
        transform: scaleY(0);
        transition: transform 0.15s ease-out;
        width: 100%;
    }

    &.active {
        color: var(--tab-active-text-color, var(--color-primary-1));
        font-weight: bold;

        &::after {
            transform: scaleY(1);
        }
    }

    span {
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
    }
}

.line {
    background: var(--tab-line-color, var(--color-grey-20));
    bottom: 1px;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
}
