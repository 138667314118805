@import "@danishagro/shared/src/styles/helpers/all";

.image {
    height: auto;
    max-height: rem(85);
    object-fit: contain;
    width: rem(70);
}

.item {
    border-top: 1px solid var(--color-grey-20);
    display: flex;
    flex-direction: row;
    gap: rem(15);
    padding: rem(20) 0;
    width: 100%;
}

.itemContent {
    flex-grow: 1;
}

.itemHeading {
    display: block;
    font-size: rem(16);
    font-weight: 400;
    margin-bottom: rem(10);
    width: 100%;

    @include mq(xs-max) {
        font-size: rem(14);
    }
}

.itemToolbar {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    gap: rem(20);
    justify-content: space-between;
}

.charges {
    color: var(--color-grey-60);
    font-size: rem(12);
    margin-top: 5px;
}

.deliveryDate {
    color: var(--color-grey-60);
    font-size: rem(12);
    margin-top: 5px;
}

.deliveryType {
    margin-top: 5px;
}

.itemsList {
    padding: rem(10) 0;
}

.driverMessage {
    margin-top: 5px;
}

.chargeItem {
    color: var(--color-grey-60);
    display: block;
    font-size: rem(10);
    text-align: right;

    > span > span {
        width: 30px;
    }
}
