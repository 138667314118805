@import "@danishagro/shared/src/styles/helpers/all";

.currentCountdown {
    display: flex;
    font-weight: 700;
    gap: 3px;
    margin-left: 3px;
}

.icon {
    width: rem(15);
}

.expiresIn {
    display: flex;
    font-size: rem(12);
    font-weight: 400;
}

.countdown {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
