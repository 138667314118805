@import "@danishagro/shared/src/styles/helpers/all";

.toolbar {
    background-color: var(--color-white);
    bottom: -1px;
    box-shadow: 0 rem(-1) rem(4) rgba(0, 0, 0, 0.25);
    left: 0;
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: 10px;
    padding-right: 10px;
    position: fixed;
    transform: translateY(-1px);
    width: 100%;
    z-index: 902;

    @media print {
        display: none;
    }
}

.inner {
    align-items: stretch;
    display: flex;
    gap: rem(20);
    height: $toolbar-height;
    justify-content: center;
    width: 100%;

    .serviceButton {
        svg {
            width: 24px;
        }

        path {
            stroke: var(--color-grey-80);
            stroke-width: 2%;
        }
    }
}

.button {
    @include clean-button;
    align-items: center;
    aspect-ratio: 1 / 1;
    color: var(--color-grey-80);
    display: flex;
    height: 100%; // for showing icons in Safari
    justify-content: center;
    padding: 10px;
    position: relative;

    &.isActive {
        color: var(--color-primary-1);

        &::after {
            opacity: 1;
            transform: scaleX(1) scaleY(1);
        }
    }
}

.icon {
    height: 20px;
    vertical-align: bottom;
}

.badge {
    bottom: 9px;
    position: absolute;
    right: 6px;
}

.searchFullWidthBtn {
    width: 100%;
}

.messageBottom {
    background-color: var(--color-primary-4);
    margin-left: -10px;
    margin-right: -10px;
    padding: 10px 50px;
    position: relative;
}

.messageBottomBox {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: rem(12);
    -webkit-line-clamp: 2;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
}

.messageTitle {
    font-weight: 600;
    margin-right: 5px;
}

.messageContent {
    position: relative;

    a {
        color: var(--color-action);
        text-decoration: underline;
    }
}

.closeMessageBtn {
    @include clean-button;
    padding: 10px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.closeIcon {
    width: 10px;
}

.bottomSheetContent {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
}

.bottomSheetCloseBtn {
    display: block;
    margin-top: 30px;
    width: 100%;
}

.bottomSheetText {
    display: block;
    min-height: 96px;

    a {
        color: var(--color-action);
        text-decoration: underline;
    }
}

.buttonLink {
    align-items: center;
    color: inherit;
    display: inline-flex;
    padding: 3px 0;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
    }
}

.buttonIcon {
    fill: currentcolor;
    flex: 1 1 rem(18);
    height: rem(18);
    width: rem(18);
}
