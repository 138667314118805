@import "@danishagro/shared/src/styles/helpers/all";

.list {
    --breadcrumb-indicator-color: var(--color-grey-80);
    --breadcrumb-text-color: var(--color-grey-80);
    align-items: center;
    font-size: rem(12);
    gap: rem(30);
    justify-content: center;
    padding-bottom: rem(40);
    padding-top: rem(40);
}

.content {
    display: flex;
    flex-direction: column-reverse;
    gap: rem(30);

    @include mq(md) {
        flex-direction: row;
    }
}

.main {
    width: 100%;

    @include mq(md) {
        flex: 1 0 calc(100% - #{rem(430)});
        max-width: calc(100% - #{rem(430)});
    }
}

.aside {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    width: 100%;

    @include mq(md) {
        flex: 0 0 rem(400);
        max-width: rem(400);
    }
}
