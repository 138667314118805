@import "@danishagro/shared/src/styles/helpers/all";

/* Styling react-photoswipe-gallery buttons */
:global(.pswp) {
    :global(.pswp__top-bar) {
        padding: 0 rem(5);
    }

    :global(.pswp__button--arrow) {
        align-items: center;
        background: var(--color-grey-20);
        display: none;
        height: rem(35);
        justify-content: center;
        margin: 0 rem(25);
        width: rem(35);

        @include mq(sm) {
            display: flex;
        }
    }

    :global(.iconArrow) {
        color: var(--color-grey-80);
        width: rem(15);
    }

    :global(.iconArrowLeft) {
        transform: scale(-1, 1);
    }

    :global(.iconTab) {
        color: white;
        width: rem(20);
    }
}

.mainSlider {
    @include border-radius(md);
    position: relative;
    user-select: none;
    width: 100%;

    @include mq(md) {
        border: 1px solid var(--color-grey-10);
    }

    &.enlarged {
        opacity: 0;
    }
}

.slide {
    align-items: normal;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;

    @include mq(xl) {
        align-items: center;
    }

    img {
        vertical-align: bottom;
    }
}

.slideMedia {
    height: 100%;
    pointer-events: none;
    width: 100%;
}

.enlargeButtonPlacementContainer {
    bottom: rem(20);
    box-sizing: border-box;
    position: absolute;
    right: rem(20);
    z-index: 999;
}

.thumbSlider {
    box-sizing: border-box;
    display: none;
    margin-right: 10px;
    margin-top: 0;
    position: relative;
    width: 80px;

    :global(.swiper-slide) {
        box-sizing: border-box;
    }

    @include mq(md) {
        display: flex;
        padding: 0 rem(14);
    }

    &.noPadding {
        padding: 0;
        padding-right: 10px;
    }
}

.overSix {
    @include mq(md) {
        padding: 0;
        padding-right: 10px;
    }
}

.thumbContainer {
    align-items: center;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
}

.thumb {
    height: inherit;
    object-fit: contain;

    &.video {
        object-fit: cover;
    }
}

.playIconContainer {
    align-items: center;
    background-color: var(--color-grey-5);
    border: none;
    color: var(--color-grey-60);
    display: flex;
    height: 50%;
    justify-content: center;
    position: absolute;
    transition: background-color 0.2s ease-in-out;
    width: 50%;
    z-index: 2;
}

.iconSize {
    width: 35%;
}

.swiperPagination {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: rem(5) 0;

    span {
        height: rem(8);
        width: rem(8);
    }

    :global(.swiper-pagination-bullet-active) {
        background: var(--color-primary-1);
    }

    @include mq(sm-max) {
        position: relative;
        top: -25px !important;
        z-index: 5;
    }

    @include mq(md) {
        display: none;
    }
}

.active,
.notActive {
    @include border-radius(md);

    img {
        @include border-radius(md);
    }
}

.active {
    border: solid 2px var(--color-action);
}

.notActive {
    border: solid 2px var(--color-grey-20);
}

.videoContainer {
    background-color: var(--color-grey-10);
    cursor: pointer;
    height: 100%;
    width: 100%;
}

.videoModalContainer {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    pointer-events: none;

    > * {
        pointer-events: auto;
    }
}

.videoModal {
    aspect-ratio: 16 / 9;
    background-color: var(--color-black);
    width: 100%;

    @include mq(sm) {
        width: 80%;
    }
}

.navigationWrapper {
    align-items: center;
    display: flex;
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;

    @include mq(sm) {
        display: none;
    }

    .buttonNext {
        display: none;

        @include mq(sm) {
            display: flex;
            margin-left: auto;
            margin-right: unset;
        }
    }

    .buttonPrev {
        display: none;
    }

    .showButton {
        display: flex;
    }

    .hideButton {
        display: none;
    }
}

.thumbWrapper {
    display: block;
    max-height: 540px;
    overflow: hidden;
}

.consentPlaceholderWrapper {
    align-items: center;
    background-color: #ededed;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    .consentPlaceholderBox {
        text-align: center;

        @include mq(md) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    h2 {
        margin: auto;
        padding: 0 20px;
        text-align: center;

        @include mq(xs-max) {
            font-size: 16px;
        }
    }

    .iconSize {
        height: 80px;
        margin-bottom: 30px;
        width: 80px;

        @include mq(xs-max) {
            display: none;
        }
    }

    .consentPlaceholderText {
        padding: 0 20px;

        > div {
            @include mq(xs-max) {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
}

.smallContent {
    .iconSize {
        display: none;
    }
}

.consentPlaceholderWrapperInner {
    background-color: transparent;

    .consentPlaceholderBox {
        background-color: #ededed;

        @include mq(sm) {
            padding: 50px;
        }
    }
}
