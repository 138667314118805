@import "@danishagro/shared/src/styles/helpers/all";

.title {
    align-items: center;
    backface-visibility: hidden;
    color: var(--title-color, var(--color-grey-80));
    display: flex;
    font-size: rem(30);
    font-weight: 700;
    line-height: 1.35;
    margin-bottom: rem(20);

    @include mq(sm) {
        max-width: rem(700);
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.noMargin {
        margin: 0;
    }

    &.fullWidth {
        @include mq(sm) {
            max-width: 100%;
        }
    }

    &.inheritColor {
        color: inherit;
    }
}

.h2 {
    font-size: rem(25);
    font-weight: 600;
    margin-bottom: rem(15);
}

.h3 {
    font-size: rem(18);
    margin-bottom: rem(15);
}

.h4 {
    font-size: rem(16);
    margin-bottom: rem(15);
}

.h5 {
    font-size: rem(14);
    margin-bottom: rem(10);
}

.titleTag {
    flex: 0 1 auto;
    font: inherit;
    margin: 0;

    &.clamp {
        @include line-clamp(var(--title-line-clamp));
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.appendix {
    flex: 0 0 auto;
    font: initial;
}
