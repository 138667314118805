@import "@danishagro/shared/src/styles/helpers/all";

.container {
    align-items: flex-end;
    pointer-events: none !important;
    width: 100%;

    .altStyle {
        display: block;

        .toastBasket {
            align-items: start;
            border-left: none;
            display: block;
            justify-content: inherit;
            min-width: auto;

            .linkToBasket {
                color: var(--color-white);
                font-size: rem(12);
                margin-top: 10px;
                padding-left: 0;
                text-decoration: underline;
            }
        }

        .toastInfo {
            div {
                font-weight: bold;
                margin-bottom: 5px;
            }
        }
    }
}

.toastWrapper {
    @include border-radius(sm);
    @include box-shadow(3);
    animation: animateToast 300ms ease-in-out forwards;
    background: var(--color-grey-90);
    color: var(--color-white);
    display: flex;
    padding: 10px;
    pointer-events: auto;
    text-decoration: none;

    @include mq(sm) {
        max-width: 300px;
    }

    &:not(.error) {
        a {
            @include link-style();
        }
    }

    &.error {
        background: #fecad3;
        color: #a71b34;

        a {
            color: currentcolor;
        }
    }
}

.toastInfo {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: rem(12);
    padding-right: rem(20);
}

.toastBasket {
    align-items: center;
    border-left: 1px solid var(--color-white);
    display: flex;
    justify-content: center;
    min-width: 65px;
}

.linkToBasket {
    color: var(--color-white);
    font-size: rem(12);
    padding-left: rem(20);
    text-decoration: underline;
}

@keyframes animateToast {
    from {
        opacity: 0;
        transform: translateY(#{rem(-10)});
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
