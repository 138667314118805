@import "@danishagro/shared/src/styles/helpers/all";

.mobileFooter {
    background-color: var(--color-grey-10);
    box-shadow: 0 1px 3px 0 rgba(#000, 0.1) inset;
    padding-bottom: calc(
        $toolbar-height + env(safe-area-inset-bottom)
    ); // Toolbar height + Safe area for iPhone X

    // Styling for print
    @media print {
        display: none;
    }
}

.mobileFooterItem {
    border-bottom: 1px solid var(--color-grey-60);
    margin: 0 rem(15);
    padding: rem(30) rem(10);

    &.noBorderBottom {
        border-bottom-width: 0;
    }
}

.itemTitle {
    display: inline-block;
    width: 120px;
}

.mobileItemTitle {
    color: var(--color-grey-90);
    display: block;
    font-size: rem(14);
    font-weight: 700;
    text-transform: initial;
}

.soMeLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
}

.soMeLink {
    display: block;
    height: 35px;
    width: 35px;
}

.soMeIcon {
    color: var(--color-grey-90);
}

.footerText {
    margin-bottom: rem(10);

    a {
        color: var(--color-grey-80);
        text-decoration: none;
    }
}

.footerLink {
    @include clean-button;
    color: var(--color-grey-80);
    display: block;
    font-size: rem(14);
    font-weight: 400;
    margin-bottom: rem(10);
    text-decoration: none;

    &:last-child {
        margin-bottom: rem(30);
    }
}

.accordion {
    margin: 0 rem(15);
}

.addressInfoWrapper {
    margin-bottom: 30px;
    position: relative;
}

.addressInfo {
    display: block;
    margin-bottom: 5px;
}
