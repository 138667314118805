@import "@danishagro/shared/src/styles/helpers/all";

.tag {
    align-items: center;
    display: inline-flex;
    gap: rem(5);
    vertical-align: bottom; // Necessary for aligning with text when inline
    white-space: nowrap;

    &.small {
        font-size: rem(12);
    }

    &.blue {
        color: var(--color-blue-1);
    }

    &.green {
        color: var(--color-accent);
    }
}

.icon {
    height: rem(20);
    width: rem(20);

    .small & {
        height: rem(18);
        width: rem(18);
    }
}
