@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    background-color: var(--color-grey-10);
    width: 100%;
}

.inner {
    align-items: flex-start;
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    padding: rem(5) rem(5) 0;
    scroll-snap-type: x mandatory;
    width: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.tabButton {
    @include clean-button;
    background-color: var(--color-grey-10);
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    padding: 10px;
    scroll-snap-align: center;
    text-transform: uppercase;
    user-select: none;

    &.isActive {
        background-color: var(--color-white);
        border-radius: 3px 3px 0 0;
        box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
        font-weight: 600;
        position: relative;
        z-index: 1;

        &::before {
            background-color: var(--color-white);
            bottom: -4px;
            content: "";
            display: block;
            height: 10px;
            position: absolute;
            width: 100%;
        }
    }
}
