@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: local(""), url("../../assets/fonts/openSans/OpenSans-Light.woff2") format("woff2"),
        url("../../assets/fonts/openSans/OpenSans-Light.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("../../assets/fonts/openSans//OpenSans-Regular.woff2") format("woff2"),
        url("../../assets/fonts/openSans/OpenSans-Regular.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src: local(""), url("../../assets/fonts/openSans/OpenSans-SemiBold.woff2") format("woff2"),
        url("../../assets/fonts/openSans/OpenSans-SemiBold.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: local(""), url("../../assets/fonts/openSans/OpenSans-Bold.woff2") format("woff2"),
        url("../../assets/fonts/openSans/OpenSans-Bold.woff") format("woff");
}
