@import "@danishagro/shared/src/styles/helpers/all";

.section {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    margin-bottom: rem(25);
    padding: 25px 15px;

    @include mq(md) {
        padding: rem(25);
    }
}

.header {
    display: flex;
    gap: rem(20);
    justify-content: space-between;
}

.headerManualAddressToggleButton {
    display: none;

    @include mq(md) {
        display: inline-flex;
    }
}

.contentManualAddressToggleButton {
    display: inline-flex;

    @include mq(md) {
        display: none;
    }
}

.inner {
    display: flex;
    flex-direction: column;
    gap: rem(20);

    &.showManual {
        display: block;
    }

    &.hideManual {
        display: none;
    }
}

.split {
    display: flex;
    gap: rem(20);
    width: 100%;
}

.largeInputWrapper {
    width: 100%;
}

.smallInputWrapper {
    width: 50%;
}

.selectWrapper {
    display: inline-block;
    max-width: 100%;
    position: relative;
    vertical-align: baseline;
    width: 100%;
}

.invalidHelperText {
    color: var(--color-alert);
    font-size: rem(16);
    margin-top: rem(5);
}
