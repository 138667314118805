@import "@danishagro/shared/src/styles/helpers/all";

.input {
    margin-bottom: rem(15);
}

.addNewContactButtons {
    display: flex;
    width: 100%;
}

.addNewContactButton {
    flex: 1;
}
