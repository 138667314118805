@import "@danishagro/shared/src/styles/helpers/all";

.header {
    background: var(--color-white);
    color: var(--color-grey-90);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 900;

    @include mq(sm) {
        border-bottom: 1px solid var(--color-grey-20);
    }
}

.navigationWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include mq(md) {
        align-items: center;
        flex-direction: row;
        gap: rem(150);
    }
}

.logoWrapper {
    width: 110px;

    @include mq(sm) {
        width: 200px;
    }
}

.logoLink {
    display: block;
    height: 46px;
    padding: 5px 0 8px;

    @include mq(sm) {
        height: 88px;
        padding: 10px 0;
    }

    svg {
        height: 100%;
        width: 100%;
    }
}

.tagline {
    color: var(--color-grey-40);
    display: flex;
    flex-direction: column;
    font-size: rem(14);

    @include mq(md) {
        font-size: rem(14);
    }
}
