@import "../../../styles/helpers/all";

@mixin smallSize {
    border-radius: rem(8);
    font-size: rem(10);
    line-height: rem(15);
    padding: 0 5px;
}

@mixin largeSize {
    border-radius: rem(11);
    font-size: rem(12);
    line-height: rem(18);
    padding: 2px 10px;
}

.tag {
    display: inline-block;
}

.sizeSmall,
.sizeDynamic {
    @include smallSize;
}

.sizeLarge {
    @include largeSize;
}

.sizeDynamic {
    @include mq(md) {
        @include largeSize;
    }
}

.good {
    background: #e7ecc9;
    color: #426716;
}

.warning {
    background: #f8e8a6;
    color: #575757;
}

.bad {
    background: #fecad3;
    color: #a71b34;
}

.neutral {
    background: #d0deeb;
    color: #103f6d;
}

.alternativeNeutral {
    background: #cdc0b1;
    color: #60482b;
}

.alternativeNeutral2 {
    background: var(--color-grey-10);
    color: var(--color-accent);
}

.yellow {
    background: var(--color-yellow-1);
    color: #575757;
}

.pause {
    background-color: var(--color-orange-4);
}
