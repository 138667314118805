@import "@danishagro/shared/src/styles/helpers/all";

.productListItem {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: rem(5) rem(5) rem(15);
    position: relative;

    @include mq(sm) {
        padding: rem(10) rem(10) rem(15);
    }

    .favBtn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        svg {
            color: var(--color-grey-80);
            width: 18px;
        }

        &.isFavorite {
            svg {
                color: var(--color-blue-1);
            }
        }

        button,
        a {
            min-height: auto;
            padding: 5px;

            &::before {
                background-color: var(--color-grey-5);
            }
        }
    }
}

.productListItemNew {
    border: 1px solid var(--color-alert);
}

.wishListButton {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.imageWrapper {
    display: flex;
    flex: 0 0 140px;
    height: 140px;
    justify-content: center;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;

    @include mq(sm) {
        flex-basis: 250px;
        height: 250px;
    }

    // .newProductBadge {
    //     background-color: var(--color-alert);
    //     color: var(--color-white);
    //     left: 0;
    //     position: absolute;
    //     top: 0;
    // }
}

.imageLink {
    display: flex;
    font-size: 0;
    justify-content: center;
    text-align: center;
    transition: transform 0.2s ease-out;
    width: 100%;

    &:hover {
        transform: scale(1.03);
    }
}

// .image {
//     height: 100%;
//     object-fit: contain;
// }

.lightText {
    color: var(--color-grey-60);
    font-size: rem(12);
}

.productName {
    font-weight: 700;
}

.productNameLink {
    color: var(--color-grey-80);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

// .videoLink {
//     background-color: var(--color-grey-40);
//     bottom: 0;
//     display: flex;
//     height: rem(25);
//     justify-content: center;
//     left: 0;
//     padding: rem(5);
//     position: absolute;
//     transition: background-color 0.15s ease-out;
//     width: rem(30);

//     &:hover,
//     &:focus {
//         background-color: var(--color-action);
//     }
// }

.productDataWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;

    @include mq(md) {
        // gap: 15px;
    }

    .pushContent {
        display: flex;
        gap: 10px;
        justify-content: space-between;

        .cartSliderBuyBtn {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .productNumber {
        color: var(--color-grey-60);

        > div {
            font-size: rem(10);
        }
    }
}

.secondaryContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

// Energy classification

.energyWrapper {
    align-items: center;
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 2px;
}

.energyImage {
    display: block;
    flex-shrink: 0;
    height: 30px;
}

.energyLabel {
    font-size: rem(12);
}

// ------------------------------

.productImageWrapper {
    // aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    width: 100%;
}

.productImage {
    aspect-ratio: 1/1;
    object-fit: contain;
    width: 100%;
}
