@import "@danishagro/shared/src/styles/helpers/all";

.textWrapper {
    padding: 0 6px;
}

.content {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.buttons {
    display: flex;
    gap: rem(20);
    width: 100%;
}

.button {
    flex: 1;
}

.errorMessage {
    color: var(--color-alert);
    display: block;
    margin-top: rem(10);
}

.error {
    margin-top: 15px;
}

.inputAddressButton {
    display: flex;
    justify-content: center;
    margin-top: rem(10);
}

.userItems {
    display: block;
}

.userItem {
    @include clean-button();
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    margin-bottom: 15px;
    padding: 10px;
    text-align: left;
    width: 100%;
}

.roletype {
    font-size: rem(12);
}

.name {
    font-size: rem(14);
    font-weight: 700;
}

.inputField {
    display: none;
}

.iconWrapper {
    color: var(--color-primary-1);
    display: flex;
    justify-content: center;
    margin-bottom: rem(20);
    max-width: rem(80);
}

.noContactWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
}
