@import "@danishagro/shared/src/styles/helpers/all";

.section {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    padding: 25px 15px;

    @include mq(md) {
        padding: rem(25);
    }
}

.header {
    display: flex;
    gap: rem(20);
    justify-content: space-between;
}

.headerSpacer {
    display: none;

    @include mq(md) {
        display: inline-block;
    }
}

.contentSpacer {
    display: inline-block;

    @include mq(md) {
        display: none;
    }
}

.inner {
    display: flex;
    flex-direction: column;
    gap: rem(20);
}

.split {
    display: contents;

    @include mq(md) {
        display: flex;
        gap: rem(20);
        width: 100%;
    }
}

.inputWrapper {
    width: 100%;
}

.buttons {
    display: flex;
    gap: rem(20);
    justify-content: flex-end;
    margin-top: rem(20);

    @include mq(sm) {
        justify-content: space-between;
    }
}
