@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    overflow: hidden;
}

.list {
    background: var(--color-grey-90);
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 70px 30px;

    @include mq(xs-max) {
        gap: 10px;
        grid-auto-flow: row;
        grid-template-columns: 1fr 1fr;
        padding: 15px;

        .uspFirst {
            grid-column: 1 / -1;
            margin-bottom: 20px;
        }
    }
}

.listItem {
    position: relative;
}

.uspItem {
    height: 100%;

    @include mq(xs-max) {
        display: block;
    }
}

.mobileFullWidth {
    @include mq(xs-max) {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        width: auto;
    }

    @include mq(xs-max) {
        .list {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.uspFirst {
    align-content: center;
    color: var(--color-white);
    display: grid;

    h2 {
        color: var(--color-white);
    }
}
