@import "@danishagro/shared/src/styles/helpers/all";

.container {
    @include border-radius(md);
    @include box-shadow(1);
    overflow: hidden;
}

.facetHeader {
    background: var(--color-grey-10);

    button {
        @include clean-button;
        font-weight: 600;
        padding: 10px 30px 2px 10px;
        position: relative;
        text-align: left;
        width: 100%;

        @include mq(sm) {
            font-weight: 700;
            padding: 5px 30px 5px 15px;
        }
    }
}

.arrow {
    color: var(--color-grey-90);
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: 20px;

    &.rotated {
        transform: translate3d(0, -50%, 0) rotate(180deg);
    }
}

.facetOptions {
    background: var(--color-grey-10);
    overflow: hidden;

    ul {
        margin: 0;
        padding: 5px 0;
    }

    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    label {
        font-weight: 400;
        margin: 0;
        padding: 0;
    }
}

.optionCheckbox {
    padding: 5px 15px;
    position: relative;
    text-transform: capitalize;

    [class*="Checkbox_checkmarkIcon"] {
        height: rem(10);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: rem(10);
    }

    [class*="Checkbox_labelInside"] {
        padding: 0;
    }

    [class*="Checkbox_input"],
    [class*="Checkbox_indicator"] {
        height: rem(15);
        left: 0;
        position: absolute;
        top: 2px;
        width: rem(15);
    }

    div[class*="Checkbox_label"] {
        padding-left: rem(12);
    }
}

.helperText {
    color: var(--color-grey-60);
    font-size: rem(12);
    margin-left: rem(10);
    padding-bottom: rem(10);

    @include mq(sm) {
        display: none;
    }
}

.facetWrapper {
    position: relative;
}
