@import "@danishagro/shared/src/styles/helpers/all";

.categoryWrapper {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    font-size: rem(14);
}

.categoryLink {
    color: var(--color-grey-80);
    overflow-wrap: anywhere;
    text-decoration: none;

    &.currentPage {
        color: var(--color-accent);
        font-weight: bold;
    }

    &:not(.currentPage):hover {
        color: var(--color-action);
        text-decoration: underline;
    }
}

.parentCategoryWrapper {
    align-items: center;
    border-bottom: 1px solid var(--color-grey-20);
    color: var(--color-action);
    display: flex;
    padding: rem(10) rem(15);
}

.parentCategoryName {
    font-weight: bold;
}

.parentLink {
    color: var(--color-action);
    text-decoration: underline;

    &:visited {
        color: currentcolor;
    }
}

.iconWrapper {
    display: flex;
    flex: 0 0 auto;
    height: rem(10);
    margin-right: 7px;
    width: rem(10);
}

.iconRotate {
    transform: rotate(180deg);
}

.listWrapper {
    list-style: none;
    margin-top: 0;
    padding: rem(10) rem(15) 0 rem(15);
}

.listItem {
    margin-bottom: rem(10);
    margin-top: rem(10);

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.contentWrapper {
    padding: rem(15);
}
