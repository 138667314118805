/// Visually hide an element, but keep it accessible to screen readers.
/// @group mixins and tools
@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
