@import "@danishagro/shared/src/styles/helpers/all";

.chip {
    @include border-radius(sm);
    background-color: var(--color-grey-60);
    color: var(--color-white);
    cursor: default;
    display: inline-flex;
    overflow: hidden;
    position: relative;

    .removable {
        padding-right: rem(35);
    }
}

.remove {
    width: rem(31);
}

.removeButton {
    @include clean-button;
    align-items: center;
    color: currentcolor;
    display: flex;
    height: 100%;
    padding: 0 rem(8);
    width: 100%;

    &::before {
        background-color: var(--color-alert);
        bottom: -1px;
        content: "";
        left: -1px;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: -1px;
        top: -1px;
        transition: opacity 0.1s ease-out;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }
}

.removeIcon {
    height: rem(15);
    position: relative;
    width: rem(15);
    z-index: 2;
}

.label {
    padding: rem(3) 0 rem(3) rem(10);
    position: relative;
    text-transform: capitalize;
    z-index: 2;

    &:only-child {
        padding-right: rem(10);
    }
}
