@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    position: relative;
}

.header {
    margin-bottom: 10px;

    @include mq(sm) {
        margin-bottom: 25px;
    }
}

.error {
    margin-top: 15px;

    @include mq(sm) {
        margin-top: 20px;
    }
}

.cartBottom {
    margin-top: 15px;

    @include mq(sm) {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }
}

.actions {
    position: absolute;
    right: 50px;
    top: 5px;

    @include mq(sm) {
        position: static;
    }
}

.cartTotal {
    @include mq(sm) {
        width: 470px;
    }

    @include mq(md) {
        width: 500px;
    }
}

.relatedProducts {
    margin: 20px -20px -10px -20px;
    padding: 10px 19px;

    & > div {
        overflow: visible !important;
    }
}
