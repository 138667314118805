@import "@danishagro/shared/src/styles/helpers/all";

.container {
    @include border-radius(md);
    @include box-shadow(1);
    align-items: flex-start;
    background-color: var(--color-white);
    display: grid;
    gap: 25px;
    grid-template-columns: 100%;
    margin-bottom: 10px;
    padding: 40px 25px;
    position: relative;

    @include mq(md) {
        grid-template-columns: 45% 55%;
    }
}

.imageWrapper {
    display: flex;
    position: relative;
}

// .splashWrapper {
//     margin-bottom: 10px;
//     position: relative;

//     .newProductSplash {
//         border-radius: rem(30);
//         font-size: rem(12);
//         font-weight: 400;
//         position: relative;
//         z-index: 1;
//     }
// }

.priceContainer {
    @include border-radius(md);
    background-color: var(--color-grey-5);
    margin: 10px 0 10px 0;
    padding: 25px;
    position: relative;
}

.priceBasics {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.priceSpaceBetween {
    justify-content: space-between;
    width: 100%;

    @include mq(sm) {
        width: auto;
    }
}

.priceContainerOptions {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    width: 100%;

    @include mq(sm) {
        justify-content: flex-end;
        width: auto;
    }
}

.priceWrapper {
    display: flex;
}

.priceBox {
    display: flex;
}

.QuantityPickerBox {
    margin-left: auto;
}

.priceContainerMobile {
    @include border-radius(md);
    background-color: var(--color-grey-10);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 10px 0 0 0;
    padding: 20px 15px;
    position: relative;

    &.priceDisabled {
        display: flex;
        gap: 10px;
        padding: 13px 15px;

        .cartBtnBox {
            flex: 1;
            margin-top: 0;
        }

        .priceWrapper {
            display: flex;
        }
    }
}

.productNumber {
    color: var(--color-grey-60);
    font-size: rem(12);
    margin-bottom: 20px;

    @include mq(sm-max) {
        display: flex;
    }
}

.stockMobileWrapper {
    margin-left: auto;
}

.selectWrapper {
    margin-top: rem(15);
}

.descriptionTextWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.descriptionText {
    color: var(--color-grey-60);
    display: inline;

    > * {
        display: inline;
    }

    a {
        @include link-style();
    }
}

.link {
    color: var(--color-action);
    margin-left: 3px;
}

.priceSection {
    position: relative;

    @include mq(md) {
        padding-right: rem(25);
    }
}

.noProduct {
    color: var(--color-alert);
    display: none;

    &.show {
        display: block;
    }
}

.specifications {
    a {
        @include link-style();
    }
}

.wishListButton {
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: 2;

    .favBtn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        svg {
            color: var(--color-grey-80);
            width: 18px;
        }

        &.isFavorite {
            svg {
                color: var(--color-blue-1);
            }
        }

        button,
        a {
            min-height: auto;
            padding: 5px;

            &::before {
                background-color: var(--color-grey-5);
            }
        }
    }
}

.deliveryLink {
    color: var(--color-action);
    display: flex;
    justify-content: flex-start;
}

.hazardStatements {
    @include border-radius(md);
    border: 2px solid var(--color-grey-20);
    margin-top: 25px;
    padding: 20px;
}

.hTextItems {
    margin: 10px 0;
    padding-left: 20px;
}

.hazardPictograms {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
}

.hazardPictogramImage {
    width: rem(60);
}

.supplementalInformation {
    margin: 10px 0;
}

.linkList {
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
    gap: 15px;
}

.linkListItem {
    @include border-radius(sm);
    background-color: var(--color-grey-10);
    padding: 10px 15px 10px 15px;
    width: max-content;
}

.titleWrapper {
    color: var(--color-grey-90);
    margin-bottom: 10px;
}

.offerAndStockWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.salesWrapper {
    @include mq(md) {
        align-items: center;
        display: flex;
        gap: rem(10);
    }
}

.salesWrapperMobile {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;

    @include mq(xxs-max) {
        flex-direction: column;
    }

    .tagSplash {
        position: relative;
        top: -2px;
    }
}

.offerWrapper {
    margin-bottom: 10px;
    position: relative;
}

.stockWrapper {
    margin-left: auto;
    margin-top: 3px;
}

.cartBtnBox {
    margin-top: 10px;
    position: relative;
}

.wishListButtonList {
    background-color: var(--color-grey-5);
    border-bottom-left-radius: var(--border-radius-medium);
    border-bottom-right-radius: var(--border-radius-medium);
    margin-bottom: 10px;
    padding: 20px 20px;
}

.extraInfoAccordion {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    border-bottom: 0;
    margin-bottom: 10px;

    section {
        box-shadow: none;
        padding: 0 5px;
        padding-bottom: 20px;
    }
}

// Energy classification

.energyWrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: fit-content;
}

.energyImage {
    display: block;
    height: 40px;
}

.energyLabel {
    font-size: rem(12);
}

.energyLargeWrapper {
    max-height: 400px;
}

.productDescription {
    a {
        color: var(--color-primary-1);
    }
}

.tableFirstTd {
    @include mq(md) {
        min-width: 250px;
    }
}

.customSelect option {
    background-color: #f5f5f5; /* Change background color */
    color: #999; /* Change text color */
    cursor: not-allowed; /* Change cursor style */
}

.helperText {
    color: var(--color-grey-80);
    font-size: rem(16);
    line-height: 18px;
    margin-top: rem(10);
    opacity: 0;

    &.show {
        color: var(--color-alert);
        opacity: 1;
    }
}

.labelTagWrapper {
    background-color: var(--color-grey-5);
    border-radius: var(--border-radius-medium);
    margin-bottom: rem(10);
    padding: 10px;
}

// .remainingTime {
//     display: flex;
//     font-size: rem(12);
//     margin-bottom: 10px;
// }

// .clockIcon {
//     margin-right: 5px;
//     position: relative;
//     top: 3px;

//     svg {
//         height: 12px !important;
//         width: 12px !important;
//     }
// }

// .bold {
//     font-weight: 700;
//     margin-left: 5px;
// }

.showDiscountBottomSheet {
    display: block;
    margin-bottom: 15px;
    margin-top: 10px;

    button {
        font-size: rem(12);
        margin: auto;
    }
}

.relatedProducts {
    margin: 20px -20px -10px -20px;
    padding: 10px 19px;

    & > div {
        overflow: visible !important;
    }
}
