@import "@danishagro/shared/src/styles/helpers/all";

.footer {
    @include box-shadow(1);
    background: var(--color-white);
    bottom: calc($toolbar-height + env(safe-area-inset-bottom));
    left: 0;
    padding: 10px;
    position: fixed;
    right: 0;
    z-index: 9;
}
