@import "@danishagro/shared/src/styles/helpers/all";

.link {
    color: inherit;
    display: flex;
    gap: 10px;
    margin: 0 -5px;
    padding: 5px;
    text-decoration: none;
}

.image {
    align-self: flex-start;
    flex: 0 0 50px;
    height: 50px;
    margin: 0;
    width: 50px;
}

.imagesrc {
    object-fit: contain;
}

.data {
    align-items: center;
    align-self: flex-start;
    display: flex;
    flex: 1 1 100%;
    gap: 10px;
}

.details {
    flex: 1 1 100%;
    font-size: rem(14);
}

.productNumber {
    color: var(--color-grey-60);
    font-size: rem(10);
}
