@import "@danishagro/shared/src/styles/helpers/all";

.secondary,
.primary {
    margin: 0 5px;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: rem(30);
}

.selectWrapper {
    width: 100%;
}

.productWrapper {
    margin-top: rem(25);
}

.productInfoWrapper {
    display: flex;
    flex-direction: column;
    min-height: 70px;

    @include mq(sm) {
        flex-direction: row;
    }
}

.imageWrapper {
    align-items: center;
    display: flex;
    flex-basis: 10%;
    padding: rem(10);
}

.image {
    height: auto;
    width: auto;
}

.productNameWrapper {
    display: flex;
    flex-basis: 75%;
    flex-direction: column;
    justify-content: flex-start;

    @include mq(sm) {
        align-items: center;
        flex-direction: row;
        padding-left: rem(15);
    }
}

.mobileWrapper {
    margin: rem(20) 0;
}

.mobileTitle {
    color: var(--color-grey-80);
    font-size: rem(14);
    font-weight: 700;

    @include mq(sm) {
        display: none;
    }
}

.mobileQuantityPickerWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: rem(20);
}

.mobileImageNameWrapper {
    align-items: center;
    display: flex;
}

.mobileImage {
    flex-basis: 20%;
    height: auto;
    width: 20%;
}

.mobileProductNameWrapper {
    flex-basis: 75%;
    font-size: rem(12);
    padding-left: rem(15);
}

.quantityQounterWrapper {
    align-items: flex-start;
    display: flex;
    flex-basis: 30%;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
}

.header {
    display: none;

    @include mq(sm) {
        align-items: center;
        background: var(--color-grey-10);
        display: flex;
        font-weight: 700;
        padding: rem(5);
    }
}

.headerItem {
    flex: 0 0 auto;
    padding-right: rem(10);

    &:first-child {
        flex-basis: 75%;
        padding-left: rem(10);
    }

    &:last-child {
        padding: 0;
    }
}

.labelTagWrapper {
    display: flex;
    justify-content: flex-end;

    @include mq(sm) {
        margin-top: 10px;
    }
}

.labelTagWrapperMobile {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
