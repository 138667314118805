@import "@danishagro/shared/src/styles/helpers/all";

.tableContainer {
    margin-bottom: 20px;
    overflow-x: auto;
}

.discountTable {
    border-collapse: separate;
    border-spacing: 0;
    text-align: left;
    width: 100%;

    th,
    td {
        border: 1px solid transparent;
        padding: 8px 12px;
    }

    td {
        border-radius: 5px;
    }

    tr:nth-child(odd) {
        background-color: var(--color-grey-5);
    }

    thead {
        tr:nth-child(odd) {
            background-color: transparent;
        }

        th {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .firstColumn {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .secondColumn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .activeRow {
        background-color: #f9f9f9;
        font-weight: bold;

        td {
            border: 1px solid var(--color-primary-1);
        }

        /* Fix overlapping borders for adjacent rows */
        .firstColumn {
            border-bottom-right-radius: 0;
            border-right: none;
            border-top: 1px solid var(--color-primary-1);
            border-top-right-radius: 0;
        }

        .secondColumn {
            border-bottom: 1px solid var(--color-primary-1);
            border-bottom-left-radius: 0;
            border-left: none;
            border-top-left-radius: 0;
        }
    }
}

.expandButton {
    color: var(--color-primary-1);
    margin-top: 10px;
    width: max-content;
}

.titleLeft {
    color: var(--color-grey-60);
    font-weight: 700;
    text-align: left;
}

.titleRight {
    color: var(--color-grey-60);
    font-weight: 700;
    text-align: right;
}

.alignRight {
    text-align: right;
}
