@import "@danishagro/shared/src/styles/helpers/all";

.header {
    margin-bottom: rem(10);

    @include mq(sm) {
        margin-bottom: rem(25);
    }
}

.filter {
    margin-bottom: rem(10);

    @include mq(sm) {
        margin-bottom: rem(15);
    }
}

.addAddress {
    @include clean-button;
    background: var(--color-white);
    border-top: 1px solid var(--color-grey-40);
    bottom: 0;
    padding: rem(12);
    position: sticky;
    text-align: left;
    width: 100%;
}
