.highlighted {
    font-weight: 700;
}

.title {
    display: block;
    margin-bottom: 20px;
}

.tag {
    background-color: #fecad3;
    border-radius: 30px;
    color: var(--color-alert);
    display: inline-block;
    font-size: 12px;
    padding: 1px 7px;
    padding-bottom: 2px;

    position: relative;
    width: fit-content;
}

.helperText {
    color: var(--color-grey-60);
    display: block;
    font-size: 12px;
}

.closeBtn {
    margin-top: 20px;
}

.urgentDeliveryLine {
    margin-right: 5px;
}
