@import "@danishagro/shared/src/styles/helpers/all";

.popUp {
    @include border-radius(md);
    @include box-shadow(3);
    background: var(--color-white);
    padding: rem(5);

    :global(.rdrMonthAndYearWrapper) {
        button,
        select,
        input {
            &:focus {
                outline: var(--color-primary-1) solid 1px !important;
            }
        }
    }
}

.wrapper {
    align-items: center;
    display: flex;
    pointer-events: none;

    > * {
        pointer-events: auto;
    }
}

.inputWrapper {
    flex: 0 0 rem(120);
    max-width: rem(120);
}

.rawInput {
    color: inherit;
    font-variant-numeric: tabular-nums;
}

.fullWidth {
    flex: 1;
    max-width: 100%;
}
