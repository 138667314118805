@import "../helpers/all";

.toaster {
    inset: 0 !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    max-width: 1500px;
    padding: 10px;

    @include mq(sm) {
        margin-top: 80px;
    }

    @include mq(md) {
        margin-top: 30px;
    }
}
