@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    padding-bottom: calc($toolbar-height + env(safe-area-inset-bottom)); // Toolbar + safe-area
}

.menu {
    display: flex;
    flex-basis: 100%;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    width: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.section {
    flex-shrink: 0;
    scroll-snap-align: start;
    width: 100%;
}

.header {
    border-bottom: 1px solid var(--color-grey-20);
    display: flex;
    flex-direction: column;
}

.item {
    align-items: center;
    border-bottom: 1px solid var(--color-grey-20);
    display: flex;
}

.link {
    color: var(--color-grey-80);
    flex: 1 1 100%;
    font-size: rem(14);
    font-weight: 600;
    padding: 12px 10px;
    text-decoration: none;
    width: 100%;

    .isActive & {
        background-color: var(--color-primary-4);
    }
}

.button {
    @include clean-button;
    align-items: center;
    color: var(--color-grey-80);
    display: flex;
    flex: 1 1 100%;
    font-size: rem(14);
    font-weight: 600;
    justify-content: space-between;
    padding: 12px 10px;
    text-decoration: none;
    width: 100%;

    .isActive & {
        background-color: var(--color-primary-4);
    }

    .arrow {
        height: rem(12);
        width: rem(12);
    }
}

.backButton {
    display: block;
    padding: 0 20px 10px 0;
    width: fit-content;

    & > span:first-child {
        height: 8px;
        max-width: 8px;
        width: 8px;
    }
}

.titleWrapper {
    padding: 10px 0;
}

.forwardLink {
    align-items: center;
    color: currentcolor;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 0;
    text-decoration: none;
}

.forwardIcon {
    height: rem(13);
    width: rem(13);
}

// Customer Service Links

.customerServiceLinks {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.customerServiceLink {
    color: var(--color-grey-80);
    display: inline-block;
    font-size: rem(14);
    font-weight: 400;
    padding: 10px;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.bottomLinks {
    background-color: var(--color-grey-5);
    display: flex;
    flex-direction: column;
    gap: rem(15);
    list-style-type: none;
    margin: 0;
    padding: rem(15) 0;
}

.linkItem {
    margin: 0 rem(20);

    > a {
        display: flex;
        font-weight: 400;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        > span:last-child {
            color: var(--color-primary-1);
            text-align: right;
            text-decoration: underline;
        }
    }
}
