@import "@danishagro/shared/src/styles/helpers/all";

.buttonWrapper {
    display: flex;
    gap: rem(15);
    margin-top: 30px;
    width: 100%;
}

.text {
    a {
        @include link-style();
    }
}
