@import "@danishagro/shared/src/styles/helpers/all";

.actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0 20px;

    @include mq(sm) {
        flex-direction: row;
    }
}

.readMoreButton {
    margin-left: auto;
}

.header {
    display: none;

    @include mq(sm) {
        align-items: center;
        background: var(--color-grey-10);
        display: flex;
        font-weight: 700;
        margin-bottom: rem(10);
        padding: 5px 15px;
    }
}

.headerItem {
    flex: 0 0 auto;
    padding-right: 10px;

    &:first-child {
        flex-basis: 100%;

        @include mq(sm) {
            flex-basis: 45%;
        }
    }

    &:nth-child(2) {
        display: none;

        @include mq(sm) {
            display: flex;
            flex-basis: 20%;
        }
    }

    &:nth-child(3) {
        display: none;

        @include mq(sm) {
            display: flex;
            flex-basis: 15%;
        }
    }

    &:last-child {
        display: none;

        @include mq(sm) {
            display: flex;
            flex-basis: 10%;
            justify-content: flex-end;
            margin: auto 0 auto auto;
            text-align: right;
        }
    }
}

.item {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-grey-20);

    &:last-child {
        border-bottom-width: 1px;
    }
}

.expandButton {
    margin-top: 10px;
}
