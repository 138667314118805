@import "@danishagro/shared/src/styles/helpers/all";

.button {
    @include clean-button;
    color: var(--color-primary-1);
    padding: rem(8);

    &.alignRight {
        margin-left: auto;
        margin-right: 0;
    }
}

.icon {
    color: var(--color-grey-80);
    display: block;
    height: rem(19);
    width: rem(19);
}

.spinner {
    align-items: center;
    display: flex;
    height: rem(19);
    justify-content: center;
    width: rem(19);
}

.popUp {
    @include border-radius(md);
    @include box-shadow(3);
    background: var(--color-white);
    color: var(--color-action);
    margin-top: rem(-10);
    padding: rem(5);
}

.popUpToolTip {
    @include border-radius(sm);
    background: var(--color-grey-90);
    color: var(--color-white);
    font-size: rem(14);
    max-width: 90vw;
    padding: 10px;
    position: relative;

    @include mq(sm) {
        max-width: 300px;
    }
}

.arrow {
    border-radius: 2px;
    height: 10px;
    position: absolute;
    visibility: hidden;
    width: 10px;
    z-index: 1;

    &::before {
        background: var(--color-grey-90);
        content: "";
        display: block;
        height: 100%;
        transform: rotate(45deg);
        visibility: visible;
    }

    [data-placement^="top"] > & {
        bottom: -5px;

        &::before {
            border-bottom-right-radius: 2px;
        }
    }

    [data-placement^="bottom"] > & {
        top: -5px;

        &::before {
            border-top-left-radius: 2px;
        }
    }

    [data-placement^="left"] > & {
        right: -5px;

        &::before {
            border-top-right-radius: 2px;
        }
    }

    [data-placement^="right"] > & {
        left: -5px;

        &::before {
            border-bottom-left-radius: 2px;
        }
    }
}

.toolTipText {
    font-size: rem(12);
}
