@import "@danishagro/shared/src/styles/helpers/all";

.container {
    margin: auto;
    max-width: #{1440px + (30px * 2)};
    padding-bottom: 15px;
    padding-left: calc(10px + env(safe-area-inset-left));
    padding-right: calc(10px + env(safe-area-inset-right));
    padding-top: 15px;
    position: relative;

    @include mq(md) {
        padding-left: calc(20px + env(safe-area-inset-left));
        padding-right: calc(20px + env(safe-area-inset-right));
    }

    @include mq(lg) {
        padding-left: calc(30px + env(safe-area-inset-left));
        padding-right: calc(30px + env(safe-area-inset-right));
    }

    & &:not(.keepNestedPadding) {
        padding-left: 0;
        padding-right: 0;
    }

    &.noVerticalPadding {
        padding-bottom: 0;
        padding-top: 0;
    }

    &.noRightPadding {
        padding-right: 0;
    }

    &.noLeftPadding {
        padding-left: 0;
    }

    &.noTopPadding {
        padding-top: 0;
    }

    &.noBottomPadding {
        padding-bottom: 0;
    }

    &.flexColumn {
        display: flex;
        flex-direction: column;
    }

    &.noOverflow {
        overflow: hidden;
    }

    :global(.modules-wrapper) & {
        height: 100%;
        padding: 0;
    }
}
