@import "@danishagro/shared/src/styles/helpers/all";

.badge {
    align-items: center;
    background: var(--color-grey-80);
    border: 1px solid var(--color-white);
    border-radius: rem(10);
    color: var(--color-white);
    display: inline-flex;
    font-size: rem(10);
    font-weight: bold;
    height: rem(19);
    justify-content: center;
    line-height: rem(17);
    min-width: rem(19);
    padding: 0 rem(2);

    &.small {
        border-color: var(--color-grey-5);
        border-radius: rem(8);
        height: rem(16);
        line-height: 1;
        min-width: rem(16);
    }

    &.action {
        background: var(--color-action);
    }

    &.alert {
        background: var(--color-alert);
    }

    &.hasSpinner {
        background: var(--color-white);
        color: var(--color-alert);

        &.small {
            background: var(--color-grey-5);
        }
    }
}
