@import "@danishagro/shared/src/styles/helpers/all";

.navigationDesktop {
    pointer-events: none;
    position: relative;
    user-select: none;
}

.list {
    display: flex;
    list-style: none;
    margin: 0 -15px;
    padding: 0;
    position: relative;
}

.item {
    align-self: center;
    display: block;
    flex: 0 0 auto;
    list-style: none;
    pointer-events: auto;
    text-transform: uppercase;
}

.link {
    @include clean-button;
    color: var(--color-grey-90);
    display: block;
    font-size: rem(16);
    padding: 10px 15px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.15s ease-out;
    transition-property: color, font-weight;
    white-space: nowrap;
    will-change: color, font-weight;

    &::after {
        background: var(--color-primary-1);
        bottom: 0;
        content: "";
        height: 3px;
        left: 5px;
        opacity: 0;
        position: absolute;
        right: 5px;
        transform: scaleX(0.95) scaleY(0.333);
        transform-origin: center bottom;
        transition: 0.15s ease-out;
        transition-property: opacity, transform;
    }

    &:hover {
        color: var(--color-primary-1);

        &::after {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.isButton {
        cursor: default;
    }

    &.opened,
    .menuClosed &.current {
        color: var(--color-primary-1);

        &,
        &:hover {
            &::after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

.megaMenuWrapper {
    @include box-shadow(1);
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-10);
    left: -20px;
    overflow-x: auto;
    padding: 20px;
    pointer-events: auto;
    position: absolute;
    right: -20px;
    top: 100%;
    z-index: 1;
}

.megaMenuList {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;
}

.column {
    flex: 0 0 20%;
    font-size: rem(14);
}

.orphanColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.columnTitle {
    color: var(--color-grey-90);
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;

    &.active,
    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}
