@import "@danishagro/shared/src/styles/helpers/all";

.modal {
    margin: 0;
    outline: none;
    padding: 3px;
}

.header {
    @include border-radius(sm);
    background: var(--color-grey-5);
    cursor: default;
    flex-direction: column;
    margin: 0;
    user-select: none;
}

.top {
    display: flex;
    justify-content: space-between;
}

.title {
    align-items: center;
    display: flex;
    padding: 5px 0 5px 15px;
}

.closeButton {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0 0 0 0;
    padding: 13px 15px;
    vertical-align: top;

    .icon {
        color: var(--color-grey-60);
        display: block;
        height: rem(15);
        width: rem(15);
    }
}

.body {
    padding: 20px 15px;

    a:not([class]) {
        @include link-style();
    }
}

.userAccountModalCloseBtn {
    .closeButton {
        position: relative;
    }
}
