@import "@danishagro/shared/src/styles/helpers/all";

.buttonLink {
    @include clean-button;
    @include link-style();
    align-items: center;
    display: inline-flex;
    text-decoration: none;
    user-select: none;

    &:disabled {
        color: var(--color-grey-20);
    }
}

.icon {
    flex: 0 0 rem(16);
    height: rem(16);
    margin-right: rem(5);
    width: rem(16);
}

.title {
    flex: 0 0 auto;

    .buttonLink:hover:not(:disabled) & {
        color: var(--color-grey-90);
    }

    .underlined & {
        text-decoration: underline;
    }
}
