@import "@danishagro/shared/src/styles/helpers/all";

.textWrapper {
    font-size: rem(12);
    margin-bottom: 15px;
    text-align: center;

    @include mq(sm) {
        margin-bottom: 30px;
    }
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.buttonWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.divider {
    border-right: 1px solid var(--color-grey-10);
}

.priceText {
    font-size: rem(12);
    margin-top: 10px;
}
