@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    display: block;
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
}

.label {
    align-self: flex-end;
    font-weight: 600;
    margin-bottom: rem(5);
}

.tooltip {
    margin-left: auto;
}

.inputWrapper {
    position: relative;
}

.helperText {
    color: var(--color-grey-80);
    font-size: rem(12);
    line-height: 18px;

    &.invalid {
        color: var(--color-alert);
    }
}

.fullWidth {
    width: 100% !important;
}
