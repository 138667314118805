@import "@danishagro/shared/src/styles/helpers/all";

$icon-size: 80px;

.container {
    @include border-radius(md);
    @include box-shadow(1);
    align-items: center;
    background-color: var(--color-white);
    display: flex;
    padding: 20px;

    @include mq(sm) {
        flex-direction: column;
        padding: 35px 15px 15px;
    }
}

.iconWrapper {
    background: var(--color-primary-1);
    border-radius: 50%;
    flex: 0 0 $icon-size;
    height: $icon-size;
    padding: 18px;
    position: relative;
    width: $icon-size;

    @include mq(xs-max) {
        height: 40px;
        margin: auto;
        width: 40px;
    }

    .icon {
        svg {
            height: 36px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50px;

            @include mq(xs-max) {
                height: 25px;
                width: 25px;
            }
        }
    }
}

.icon {
    color: var(--color-white);
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
}

.textWrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;

    @include mq(xs-max) {
        padding-left: 0;
        padding-top: 10px;
        text-align: center;
    }

    @include mq(sm) {
        flex: 1 1 100%;
        min-height: rem(60);
        padding: 20px 0 0;
        text-align: center;
    }
}

.text {
    flex: 0 0 auto;
}

.uspTitle {
    font-weight: 700;

    @include mq(xs-max) {
        display: block;
        margin-bottom: 5px;
        margin-top: 5px;
    }
}
