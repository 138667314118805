@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    display: inline-block;
    max-width: 100%;
    position: relative;
    vertical-align: baseline;

    &.disabled {
        color: var(--color-grey-40);
    }

    &.fullWidth {
        width: 100%;
    }
}

.groupLabel {
    color: var(--color-grey-80);
    font-size: rem(14);
    font-weight: 700;
    padding-bottom: 5px;
    text-transform: none;
}

.extraText {
    font-size: rem(12);
}

.helperWrapper {
    color: var(--color-grey-80);
}

.helperValue {
    font-weight: 700;
}

.variantText {
    color: var(--color-grey-60);
    font-size: rem(12);
    position: relative;
    top: -1px;
}

.singleValue {
    display: block;
}

.singleValue + input {
    @include mq(xs-max) {
        left: 0 !important;
        transform: none;
    }
}

.disableOptionStyling {
    [aria-disabled="true"] {
        color: var(--color-grey-20);
        cursor: not-allowed;
    }
}
