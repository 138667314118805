@import "@danishagro/shared/src/styles/helpers/all";

.inputWrapper {
    @include border-radius(sm);
    align-items: center;
    background: var(--color-white);
    border: 1px solid var(--color-grey-40);
    box-shadow: inset 0 0 0 1px transparent;
    display: flex;
    transition: 0.1s ease-out;
    transition-property: border-color, box-shadow;

    &.disabled {
        background-color: var(--color-grey-5);
        pointer-events: none;
    }

    &:not(.disabled):not(.invalid):hover,
    &:focus-within,
    &.showAsFocused {
        border-color: var(--color-primary-1);
    }

    &:focus-within,
    &.showAsFocused {
        box-shadow: inset 0 0 0 1px var(--color-primary-1);
    }

    &.invalid {
        border-color: var(--color-alert);
        box-shadow: inset 0 0 0 1px var(--color-alert);
    }

    &.highlighted {
        border-color: var(--color-primary-1);
    }
}

.input {
    @include placeholder(var(--color-grey-40));
    background-color: transparent;
    border: 0;
    flex: 1 1 100%;
    font: inherit;
    font-size: max(#{rem(16)}, 16px); // Must be at least 16px to avoid iOS zooming in
    height: calc(#{rem(40)} - 2px);
    outline: none;
    padding: 0 rem(5) 0 rem(10);
    width: 100%;

    &:disabled {
        color: var(--color-grey-40);
        opacity: 1;
        -webkit-text-fill-color: var(--color-grey-40);
    }

    .hasIcon & {
        padding-left: var(--input-padding-left, #{rem(30)});
    }
}

.rightText {
    padding-right: 8px;
}

.icon {
    height: rem(12);
    left: rem(10);
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: rem(12);

    .disabled + & {
        color: var(--color-grey-40);
    }
}
