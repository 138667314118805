@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    display: block;
    width: 100%;

    .noPaddingTopBottom.noPaddingTopBottom {
        min-height: 260px;
        padding-bottom: 0;
        padding-top: 0;

        .contentWrapper {
            flex-grow: 0.6;
        }
    }
}

.inner {
    align-items: stretch;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    position: relative;

    @include mq(sm) {
        flex-direction: row;
        margin-bottom: 0;
        min-height: 350px;
    }

    // Settings: Image Cover

    .layoutCoverBlackFilter & {
        @include mq(sm) {
            padding-bottom: calc(50px * 1);
            padding-left: calc(50px * 1);
            padding-right: calc(50px * 1);
            padding-top: calc(50px * 1);
        }

        @include mq(md) {
            padding-bottom: calc(50px * 2);
            padding-left: calc(50px * 2);
            padding-right: calc(50px * 2);
            padding-top: calc(50px * 2);
        }

        &::before {
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.65) 0%,
                rgba(0, 0, 0, 0.45) 70%,
                rgba(0, 0, 0, 0) 100%
            );
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;

            @include mq(sm) {
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 5%, rgba(0, 0, 0, 0) 50%);
            }
        }

        @include mq(sm) {
            min-height: 650px;
        }
    }

    // Settings: Image Left/Right

    .heroImageLeft & {
        gap: 20px;

        @include mq(sm) {
            gap: 50px;
            min-height: 400px;
        }
    }
}

// IMAGE

.imageWrapper {
    width: 100%;

    @include mq(sm) {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }

    // Settings

    .layoutCoverBlackFilter & {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }

    .image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

// CONTENT

.contentWrapper {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    z-index: 2;

    @include mq(sm) {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }

    @include mq(md) {
        flex-grow: 0.6;
    }

    @include mq(lg) {
        flex-grow: 0.4;
    }

    // Settings: Image Left/Right

    .heroImageLeft & {
        padding-bottom: 50px;
        padding-top: 50px;
    }
}

.content {
    --title-color: var(--hero-text-color, var(--color-grey-80));
    color: var(--hero-text-color, var(--color-grey-80));
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 60ch;

    .heroImageLeft & {
        max-width: 100ch;
        padding-left: 20px;
        padding-right: 20px;

        @include mq(sm) {
            padding-left: 0;
            padding-right: 50px;
        }
    }

    // Settings

    .layoutCoverBlackFilter & {
        --title-color: var(--color-white);
        color: var(--color-white);
    }
}

.tagline {
    font-size: rem(16);
}

.readMoreLink {
    width: max-content;
}

.fullWidthMobile {
    @include mq(xs-max) {
        margin-left: -10px;
        margin-right: -10px;
        width: auto;
    }
}

.overlayFullWidth {
    .noPaddingTopBottom {
        &::before {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 5%, rgba(0, 0, 0, 0) 80%);
        }
    }
}

.videoWrapper {
    overflow: hidden;
    width: 100%;

    @include mq(sm) {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }

    // Settings

    .layoutCoverBlackFilter & {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }

    .image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}
