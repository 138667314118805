@import "@danishagro/shared/src/styles/helpers/all";

.discountTableItem {
    &:nth-child(odd) {
        background-color: var(--color-grey-5);
    }
    border-radius: 5px;
    display: grid;
    font-size: rem(12);
    gap: 10px;
    grid-template-columns: 50px 2fr 1fr;
    padding: 7px 10px;
}

.discountTable {
    position: relative;
}

.amount {
    position: relative;
}

.pricePerPcs {
    position: relative;
}

.price {
    position: relative;
    text-align: right;
}
