@import "@danishagro/shared/src/styles/helpers/all";

.container {
    align-items: baseline;
    display: flex;
    font-size: rem(12);

    @include mq(sm) {
        font-size: rem(14);
    }
}

.status {
    background: var(--color-primary-1);
    border-radius: 50%;
    flex: 0 0 auto;
    height: rem(12);
    margin-right: 5px;
    width: rem(12);
}

.statusLarge {
    height: rem(15);
    width: rem(15);
}

.fewInStock {
    background: var(--color-yellow-1);
}

.discontinued {
    background: var(--color-alert);
}

.comment {
    color: var(--color-grey-60);
}

.stockTextLarge {
    position: relative;
    top: -3px;
}
