@import "@danishagro/shared/src/styles/helpers/all";

.titleWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;
}

.toggleWrapper {
    align-items: center;
    display: flex;

    label {
        font-size: rem(14);
        margin-right: 15px;
    }
}

.categoryHeading {
    align-items: center;
    display: flex;
    gap: rem(20);
    justify-content: space-between;
    margin-bottom: rem(20);
}

/*---------------------------------------*/

.container {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;

    @include mq(md) {
        flex-direction: column;
    }
}

.innerContainer {
    flex: 0 0 auto;
}

.content {
    @include mq(md) {
        display: flex;
    }
}

.menuWrapper {
    margin-bottom: 15px;

    @include mq(md) {
        flex: 0 0 295px;
        margin: 0;
        padding-right: 25px;
    }

    @include mq(lg) {
        flex-basis: 280px;
        padding-right: 20px;
    }
}

.menu {
    @include mq(md) {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
}

.results {
    @include mq(md) {
        flex: 1 1 100%;
    }

    &.dimmed {
        animation: dim 0.5s ease-out forwards;
        opacity: 0.5;
        pointer-events: none;
    }
}

.loadMoreWrapper {
    display: flex;
    justify-content: center;
    padding: rem(40) 0;
    width: 100%;
}

.loadMoreWrapperFull {
    margin-top: 30px;
}

.spinner {
    flex: 0 0 auto;
    height: rem(35);
    padding: rem(10);
    width: rem(35);

    @include mq(sm) {
        margin-right: 20px;
    }
}

.placeholder {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.leaf {
    color: var(--color-grey-10);
    height: 100px;
    margin: 10px;
    width: 100px;

    @include mq(md) {
        margin-top: 50px;
    }
}

.vetMedicationLink {
    bottom: 15px;
    position: fixed;
    right: 15px;
    z-index: 2;

    @include mq(sm-max) {
        bottom: calc($toolbar-height + env(safe-area-inset-bottom) + 65px);
    }
}

.vetMedicationImage {
    max-width: 80px;
    vertical-align: bottom;

    @include mq(sm) {
        max-width: none;
    }
}
