@import "@danishagro/shared/src/styles/helpers/all";

.container {
    padding: 5px 0;

    @include mq(sm) {
        align-items: flex-start;
        display: flex;
        gap: 40px;
        justify-content: space-between;
    }

    @include mq(md) {
        align-items: center;
    }
}

.header {
    padding-bottom: 15px;

    @include mq(sm) {
        padding: 2px 0;
    }

    @include mq(md) {
        padding: 0;
    }
}

.list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    @include mq(xs) {
        align-items: flex-end;
    }

    @include mq(md) {
        flex-direction: row;
        gap: 25px;
        justify-content: flex-end;
        text-align: right;
    }

    @include mq(lg) {
        gap: 40px;
    }
}

.item {
    user-select: none;

    &:not(:last-child) {
        margin-bottom: 15px;

        @include mq(md) {
            margin: 0;
        }
    }

    @include mq(sm) {
        white-space: nowrap;
    }
}

.label {
    align-items: center;
    display: flex;
    font-size: rem(14);
    justify-content: space-between;
    width: 100%;

    @include mq(sm) {
        justify-content: flex-start;
    }
}

.switch {
    font-size: 0;
    margin-left: 10px;
}
