/// Clean a button of its default browser styling.
/// @group mixins and tools
/// @example
///   button {
///       @include clean-button;
///       color: blue;
///       text-decoration: underline;
///   }
@mixin clean-button {
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    &[disabled] {
        cursor: default;
    }
}
