@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    z-index: 2;
}

.button {
    justify-content: center;

    &.directionLeft {
        transform: rotate(180deg);
    }

    &.directionTop {
        transform: rotate(-90deg);
    }

    &.directionBottom {
        transform: rotate(90deg);
    }
}
