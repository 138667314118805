@import "@danishagro/shared/src/styles/helpers/all";

.contentWrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    position: relative;
    transition: grid-template-rows 400ms ease-in-out;

    &::before {
        background: linear-gradient(transparent -50%, white);
        bottom: 0;
        content: "";
        height: 50%;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &.open {
        grid-template-rows: 1fr;

        &::before {
            animation: hideHeight 450ms ease-in-out forwards;
        }
    }

    // No gradient-overlay on .contentWrapper if notExpandable state is true
    .notExpandable & {
        &::before {
            height: 0;
        }
    }
}

.content {
    min-height: 120px;

    // Auto min-height if notExpandable state is true
    .notExpandable & {
        min-height: auto;
    }
}

.expandButton {
    display: block;
    margin-top: 20px;

    // Do not display button if notExpandable state is true
    .notExpandable & {
        display: none;
    }
}

@keyframes hideHeight {
    from {
        height: 50%;
    }

    to {
        height: 0;
    }
}
