@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    padding-bottom: rem(50);
}

.header {
    padding-bottom: rem(30);
    padding-top: rem(30);
}

// Banner

.banner {
    display: flex;
    flex-direction: column;
    gap: rem(30);
    width: 100%;

    @include mq(xs-max) {
        padding: 30px 10px;
    }

    @include mq(sm) {
        flex-direction: row;
        text-align: left;
    }
}

.bannerIcon {
    color: #849938;
    flex-shrink: 0;
    width: rem(80);

    @include mq(xs-max) {
        margin: auto;
    }
}

.bannerContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: rem(10);

    @include mq(xs-max) {
        text-align: center;
    }

    > div {
        @include mq(xs-max) {
            margin: auto;
        }
    }
}

// Button

.buttonWrapper {
    margin-top: rem(30);
    text-align: right;

    @include mq(md) {
        text-align: left;
    }
}
