@import "@danishagro/shared/src/styles/helpers/all";

.mobilItem {
    display: grid;
    gap: 15px;
    grid-template-columns: 30px 1fr 30px;
    position: relative;

    .boxImage {
        display: block;
    }

    .imageWrapper {
        width: 40px;

        .imageLink {
            mix-blend-mode: darken;
        }
    }

    .boxInfo {
        .name {
            font-weight: 600;
        }
    }

    .deliveryDate {
        margin-top: 5px;

        .deliveryDateInfo {
            display: flex;

            .dateLabel {
                font-size: rem(12);
                margin-right: 5px;
            }
        }
    }

    .total {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 10px;

        .totalAmount {
            font-size: rem(12);
            font-weight: 400;
            text-align: left;
        }

        .totalPrice {
            font-size: rem(12);
            font-weight: 600;
            text-align: right;
        }
    }
}

.numberAndName {
    flex: 1 1 auto;
}

.name {
    font-size: rem(12);

    @include mq(sm) {
        font-size: rem(14);
    }
}

.nameLink {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.charges {
    color: var(--color-grey-60);
    font-size: rem(10);
    margin-top: 2px;
}

.boxButton {
    align-items: center;
    background-color: var(--color-grey-10);
    display: flex;
    justify-content: center;
    margin-bottom: -21px;
    margin-left: 10px;
    margin-right: -15px;
    margin-top: -21px;

    button {
        @include clean-button;
        height: 100%;
        position: relative;
        width: 100%;

        span {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .buttonIcon {
        color: var(--color-primary-1);

        svg {
            width: 15px;
        }
    }
}

.image {
    height: auto;
    max-height: 60px;
    vertical-align: bottom;
    width: auto;

    @include mq(sm) {
        max-height: none;
    }
}

.driverMessageWrapper {
    @include line-clamp(1);
    margin-top: rem(5);
    padding: rem(3) rem(0);
    width: 100%;
}

.driverMessage {
    @include line-clamp(1);
}

.tag {
    background-color: #fecad3;
    border-radius: 30px;
    color: var(--color-alert);
    font-size: rem(9);
    padding: 1px 7px;
    padding-bottom: 2px;

    position: relative;
    top: 1px;
}

.checkItem {
    z-index: 1;
}

.combinedDelivery {
    display: flex;
    margin-top: 5px;
}

.combinedDeliveryIcon {
    margin-right: 5px;
    position: relative;
    top: 1px;
    width: 25px;
}

.combinedDeliveryText {
    color: var(--color-primary-1);
    font-size: rem(12);
}
