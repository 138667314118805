@import "@danishagro/shared/src/styles/helpers/all";

.categoryLink {
    color: var(--color-grey-80);
    display: flex;
    justify-content: space-between;
    overflow-wrap: anywhere;
    text-decoration: none;

    &.currentPage {
        color: var(--color-grey-80);
        font-weight: 600;
    }

    &:not(.currentPage):hover {
        color: var(--color-action);
        text-decoration: underline;
    }

    .iconSelected {
        width: 10px;
    }
}

.listWrapper {
    list-style: none;
    margin: 0;
    margin-top: 0;
    padding: 0;
}

.listWrapper > a {
    font-weight: 600;
    margin-bottom: rem(5);
    margin-top: rem(5);
    padding: rem(5) rem(10);

    &.currentPage {
        background-color: var(--color-primary-5);
    }
}

.listItem {
    margin-bottom: rem(5);
    margin-top: rem(5);
    padding: rem(5) rem(10) rem(5) rem(25);

    &.currentPage {
        background-color: var(--color-primary-5);
        font-weight: 600;
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.contentWrapper {
    margin: 0;
    padding: 0;
}

.container {
    display: grid;
    gap: rem(10);
    grid-template-columns: 1fr 3fr;
    min-height: 400px;
}

.contentBox {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: rem(15);

    + .contentBox {
        margin: 0;
    }
}

.boxTitle {
    border-bottom: 1px solid var(--color-grey-10);
    box-sizing: border-box;
    display: grid;
    font-size: rem(16);
    font-weight: bold;
    grid-template-areas: "a b c";
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: rem(10);
    padding: 0 0 rem(10) 0;
}

.productNumberTitle {
    grid-area: b;
    justify-self: center;
}

.priceTitle {
    grid-area: c;
    justify-self: end;
    margin-right: 10px;

    .hasScroll & {
        margin-right: 25px;
    }
}

.productListWrapper {
    flex: 1;
    position: relative;
}

.productList {
    inset: 0;
    overflow-y: auto;
    position: absolute;

    p {
        width: 200px;
    }
}

// Specific Safari override to always show scrollbar
_::-webkit-full-page-media,
_:future,
:root .hasScroll .productList {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        appearance: none;
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
}

.productItem {
    color: var(--color-grey-80);
    cursor: pointer;
    display: grid;
    font-size: rem(14);
    grid-template-areas: "a b c";
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: rem(5);
    min-width: 100%;
    padding: rem(5) rem(10);
    text-decoration: none;

    &:hover {
        background-color: var(--color-grey-5);
    }

    &.productItemSelected,
    &.productItemSelected:hover {
        background-color: var(--color-primary-5);
        font-weight: 600;
    }
}

.col2 {
    grid-area: b;
    justify-self: center;
}

.col3 {
    grid-area: c;
    justify-self: end;
}

.selectedProductWrapper,
.emptyProductWrapper {
    grid-column: 1 / -1;
    margin-top: rem(25);
}

.emptyProductWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(25);

    h3,
    p {
        margin-bottom: 0;
    }
}

.iconWrapper {
    aspect-ratio: 1/1;
    border: 3px solid var(--color-action);
    border-radius: 100vw;
    color: var(--color-action);
    display: flex;
    justify-content: center;
    width: 80px;
}

.iconEmpty {
    width: 40px;
}
