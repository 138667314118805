@import "@danishagro/shared/src/styles/helpers/all";

// CSS Custom Properties
// --breadcrumb-text-color
// --breadcrumb-text-hover-color
// --breadcrumb-indicator-color

.list {
    counter-reset: css-counter 0;
    display: flex;
    flex-wrap: wrap;
    gap: rem(5);
    list-style: none;
    padding: rem(15) 0 rem(20);
    user-select: none;

    &.hideOnMobile {
        display: none;

        @include mq(md) {
            display: flex;
        }
    }
}

.item {
    align-items: center;
    color: var(--breadcrumb-text-color, var(--color-grey-40));
    display: flex;
    flex: 0 0 auto;

    .showNumberedList & {
        counter-increment: css-counter 1;

        &::before {
            content: counter(css-counter) ". ";
            padding-right: rem(5);
        }
    }
}

.link {
    color: inherit;
    flex: 0 0 auto;
    text-decoration: none;
    transition: color 0.15s ease-out;

    &:hover {
        color: var(--breadcrumb-text-hover-color, var(--color-grey-60));
        text-decoration: underline;
    }

    .isHighlighted & {
        font-weight: 700;
    }
}

.divider {
    color: var(--breadcrumb-indicator-color, var(--color-grey-40));
    flex: 0 0 auto;
    height: rem(10);
    width: rem(10);
}

.title {
    cursor: default;
}

.expandBtn {
    @include clean-button;
}
