@import "@danishagro/shared/src/styles/helpers/all";

.container {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    @include mq(sm) {
        display: block;
    }
}

.priceLabel {
    grid-area: 1 / 1 / 2 / 2;
}

.price {
    grid-area: 1 / 2 / 3 / 3;
    padding-left: 10px;
    text-align: right;

    &.largeSpacing {
        @include mq(sm) {
            line-height: unset;
        }
    }

    @include mq(sm) {
        align-items: baseline;
        display: flex;
        line-height: 1;
        padding-left: 0;
    }
}

.displayAsColumn {
    flex-direction: column;

    @include mq(sm) {
        margin-top: 10px;
    }
}

.currentPrice {
    flex: 0 0 auto;
    font-size: rem(20);
    font-weight: 700;

    @include mq(sm) {
        font-size: rem(40);

        .label {
            display: none;
        }
    }
}

.quickOrderPrice {
    font-size: rem(25);
}

.discountedPrice {
    flex: 0 0 auto;
    text-decoration: line-through;

    @include mq(sm) {
        margin-left: 5px;
    }
}

.comment {
    color: var(--color-grey-60);
    font-size: rem(12);
    grid-area: 2 / 1 / 3 / 2;
}

.alternativeContainer {
    display: block;

    .price {
        padding-left: 0;
        text-align: left;
    }
}

.alternativePriceLabel {
    color: var(--color-grey-60);
    font-size: 12px;
}
