.errorWrapper {
    align-items: center;
    border: 1px solid var(--color-grey-10);
    display: flex;
    height: 100%;
    justify-content: center;
    line-height: 10px;
    padding: 15px;
    text-align: center;
}

.errorItem {
    display: inline-block;
}
