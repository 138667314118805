@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    padding-bottom: rem(50);
}

.header {
    padding-bottom: rem(30);
    padding-top: rem(30);
}

.inner {
    display: flex;
    flex-direction: column-reverse;
    gap: rem(30);

    @include mq(xs-max) {
        gap: rem(15);
    }

    @include mq(md) {
        flex-direction: row;
    }
}

.content {
    width: 100%;

    @include mq(md) {
        flex: 1 0 calc(100% - #{rem(430)});
        max-width: calc(100% - #{rem(430)});
    }
}

.aside {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    width: 100%;

    @include mq(md) {
        flex: 0 0 rem(400);
        max-width: rem(400);
    }
}

.section {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    padding: rem(25) rem(15);
    width: 100%;

    @include mq(md) {
        padding: rem(25);
    }

    & + .section {
        margin-top: rem(30);

        @include mq(xs-max) {
            margin-top: rem(15);
        }
    }
}

.toolbarButtonWrapper {
    align-items: center;
    display: flex;
    gap: rem(10);
}

.toolbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: rem(10);
    width: 100%;

    @include mq(md) {
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: rem(30);
    }
}

.split {
    display: flex;
    gap: rem(30);

    & > *:first-child {
        flex-grow: 1;
    }
}

.accountDetailsInner {
    & > * {
        margin-top: rem(30);
    }

    &.isCollapsed {
        display: none;
    }
}

.noteWrapper {
    @include border-radius(md);
    background-color: var(--color-grey-5);
    border: rem(1) solid var(--color-grey-20);
    padding: rem(15);
}

.noteHeading {
    color: var(--color-grey-80);
    font-size: rem(14);
    font-weight: 600;
}

.submitButtonWrapper {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: rem(15);
    width: 100%;
}

.error {
    color: var(--color-alert);
    display: block;
    font-size: rem(14);
    padding: rem(10) 0 0 0;
}

.accountWrapper {
    input {
        font-size: rem(14);
    }
}

.driverMessage {
    resize: none !important;
}

.toolbarDelivery {
    gap: 0;
}

.deliveryOptionsWrapper {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    gap: rem(20);
    list-style-type: none;
    margin: rem(20) 0;
    padding: 0;

    > li {
        flex-basis: 50%;
    }

    @include mq(md) {
        flex-direction: row;
        justify-content: flex-start;
        margin: rem(20) 0;
    }
}

.extendedInfoWrapper {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    gap: rem(20);
    margin: rem(20) 0;
    padding: 0;

    > div {
        flex: 1 1 50%;
    }

    @include mq(md) {
        flex-direction: row;
        justify-content: flex-start;
        margin: rem(20) 0 0 0;
    }
}

.sectionDeliveryAddress {
    max-height: 150px;
    transition: all 0.2s ease-out;
}

.sectionDisabled {
    max-height: 0;
    opacity: 0;
    padding-bottom: 0;
    padding-top: 0;

    .section + & {
        margin-top: 0;
    }
}

.deliveryOption {
    @include border-radius(sm);
    align-items: flex-start;
    background-color: var(--color-grey-5);
    border: 1px solid var(--color-grey-40);
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    padding: rem(15);
    position: relative;

    &.selected {
        animation: bg 1s ease-out forwards;
        background: linear-gradient(
            90deg,
            var(--color-primary-5),
            var(--color-primary-5),
            var(--color-primary-4),
            var(--color-grey-5),
            var(--color-grey-5)
        );
        background-position-x: 100%;
        background-repeat: no-repeat;
        background-size: 500%;
    }
}

@keyframes bg {
    0% {
        background-position-x: 100%;
    }

    100% {
        background-position-x: 0%;
    }
}

.labelTitle {
    align-items: center;
    display: flex;
    font-weight: 600;
    gap: rem(10);
    margin-bottom: rem(7);
}

.labelDescription {
    font-size: rem(12);
}

.deliveryDateWrapper {
    flex-basis: 100%;

    *[class^="DatePicker_inputWrapper"] {
        flex-basis: 100%;
        max-width: none;

        *[class^="Input_input"] {
            text-align: right;
        }
    }
}
