@import "@danishagro/shared/src/styles/helpers/all";

.titleWrapper {
    display: flex;
    justify-content: space-between;

    @include mq(sm) {
        justify-content: flex-start;
    }
}

.title {
    font-size: rem(12);
    padding-right: 10px;
}

.scrollIconWrapper {
    color: var(--color-grey-60);
    flex: 0 0 auto;
    height: rem(15);
    width: rem(15);
}

.list {
    display: flex;
    list-style: none;
    margin: rem(5) -10px 20px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    overflow-x: auto;
    padding-left: 10px;
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.listItem {
    margin-right: rem(10);
}

.listItemLink {
    @include border-radius(sm);
    background-color: var(--color-grey-10);
    color: var(--color-grey-80);
    display: block;
    padding: rem(5);
    text-decoration: none;
    user-select: none;
    white-space: nowrap;

    .isActive & {
        background-color: var(--color-accent);
        color: var(--color-white);
    }
}
