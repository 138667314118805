@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    border-bottom: var(--accordion-border, 1px solid var(--color-grey-60));
}

.header {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--accordion-header-padding, #{rem(30)} #{rem(10)});
    text-transform: uppercase;
    user-select: none;
    width: 100%;
}

// Header

.title {
    color: var(--accordion-label-color, var(--color-grey-90));
    font-size: var(--accordion-title-font-size, #{rem(14)});
    font-weight: 700;
    text-transform: initial;

    .isMedium & {
        font-size: rem(18);
        font-weight: 700;
    }

    .isLarge & {
        font-size: rem(32);
        font-weight: 300;
        text-transform: uppercase;
    }
}

.toolbar {
    display: flex;
    gap: rem(20);

    .isMedium & {
        gap: rem(10);
    }
}

.tagline {
    color: var(--color-grey-90);
    display: none;
    font-size: rem(14);
    font-weight: 700;
    text-transform: initial;

    .isMedium & {
        font-size: rem(16);
        font-weight: 600;
    }

    .isLarge & {
        font-size: rem(26);
        font-weight: 300;
        text-transform: uppercase;
    }

    @include mq(md) {
        display: block;
    }
}

// Toggle

.toggleWrapper {
    align-items: center;
    color: var(--accordion-toggle-color, var(--color-grey-90));
    display: flex;
    gap: rem(5);
    justify-content: center;
}

.toggleIcon {
    color: var(--accordion-icon-color, currentColor);
    width: var(--accordion-toggle-size, #{rem(15)});

    .isMedium & {
        color: var(--color-grey-90);
        width: var(--accordion-toggle-size, rem(25));
    }
}

// Children

.content {
    padding: 0 rem(10);
}

.isSmall {
    .header {
        padding: 15px;
    }

    .noPaddingHeader {
        padding: 0;
    }
}

.animationContent {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 rem(10);
    transition: max-height 0.3s ease-out, opacity 0.3s ease-in-out;
}

.show {
    opacity: 1;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-in-out;
}

.headerFullWidth {
    width: 100%;
}

.allowThickBorder {
    position: relative;

    &::before {
        background-color: var(--color-action);
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 5px;
    }
}

.showLargeArrow {
    height: 30px;
    width: 30px;
}

.salesTabStatisticStyle {
    .showMoreLess {
        min-width: 105px;

        svg {
            color: var(--color-action);
            position: relative;
            top: 2px;
        }

        &:hover {
            svg {
                color: var(--color-grey-90);
            }
        }
    }
}
