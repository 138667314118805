@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    background: var(--color-white);
    border-top: 1px solid var(--color-grey-5);
    display: block;
    height: calc(100% - 46px);
    left: 0;
    position: fixed;
    top: 46px;
    transform: translateX(110%);
    width: 100%;
    z-index: 900;

    @include mq(sm) {
        top: 80px;
    }

    &.isOpen {
        transform: translateX(0);
        transition-duration: 0.3s;
    }
}

.inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;

    @include mq(sm) {
        height: calc(100vh - 80px); // Viewport - Header
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin: 0;
    padding-bottom: calc(
        $toolbar-height + 10px + env(safe-area-inset-bottom)
    ); // Toolbar + padding + safe-area
}

.button {
    margin-top: 15px;
    width: fit-content;
}

// Customer Service Links

.customerServiceLinks {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.customerServiceLink {
    color: var(--color-grey-80);
    display: inline-block;
    font-size: rem(14);
    font-weight: 400;
    padding: 10px 0;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}
