@import "@danishagro/shared/src/styles/helpers/all";

.priceWrapper {
    display: block;
    width: 100%;
}

.price {
    grid-area: 1 / 2 / 3 / 3;

    @include mq(sm) {
        align-items: flex-end;
        display: flex;
        padding-left: 0;
    }
}

.currentPrice {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    font-size: rem(16);
    font-weight: 700;
    gap: 4px;

    @include mq(sm) {
        flex-direction: row;
        font-size: rem(22);
        line-height: 1;
    }
}

.discountedPrice {
    flex: 0 0 auto;
    font-size: rem(12);
    font-weight: 400;
    line-height: 1em;
    margin-top: 5px;
    text-decoration: line-through;

    @include mq(md) {
        font-size: rem(16);
    }
}

.pushContent {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    gap: 10px;
    justify-content: space-between;
}

.shippingLink {
    font-size: rem(12);
}

// .quantityDiscount {
//     align-items: center;
//     color: var(--color-grey-60);
//     display: flex;
//     font-size: rem(12);
//     margin-right: rem(-5);
// }

.discountTableItem {
    display: flex;
    font-size: rem(12);
    margin-bottom: 5px;

    .discountTable {
        position: relative;
    }

    .amount {
        min-width: 50px;
        position: relative;
    }

    .pricePerPcs {
        min-width: 100px;
        position: relative;
    }

    .price {
        justify-content: flex-end;
        margin-left: auto;
        position: relative;
        text-align: right;
        width: 50px;
    }
}

.priceClickable {
    position: relative;
}

.onlyPrice {
    font-size: rem(14);
    font-weight: 400;
}
