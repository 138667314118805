@import "@danishagro/shared/src/styles/helpers/all";

.header {
    align-items: center;
    background: var(--color-grey-10);
    display: flex;
    font-weight: 700;
    margin-bottom: rem(10);
    padding: 5px 20px;
}

.headerItem {
    flex: 0 0 auto;
    white-space: nowrap;

    &:first-child {
        flex: 1 1 100%;

        @include mq(sm) {
            flex: 1 1 55%;
            min-width: 300px;
        }
    }

    &:nth-child(2) {
        display: none;

        @include mq(sm) {
            display: flex;
            flex-basis: 15%;
            min-width: 190px;
        }
    }

    &:nth-child(3) {
        @include mq(sm) {
            display: flex;
            flex-basis: 15%;
            justify-content: center;
        }
    }

    &:last-child {
        display: none;

        @include mq(sm) {
            display: flex;
            flex-basis: 15%;
            justify-content: flex-end;
            margin: auto 0 auto auto;
            padding: 0;
            text-align: right;
        }
    }

    &.isShown {
        visibility: visible;
    }

    &.isHidden {
        pointer-events: none;
        visibility: hidden;
    }
}

.item {
    overflow: hidden;
}

.headerItemInner {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    position: relative;
    width: 150px;
}

.quickSelectTogggleButton {
    align-items: center;
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
}

.quickSelectTogggleIcon {
    height: 16px;
    width: 16px;
}
