@import "@danishagro/shared/src/styles/helpers/all";

.input {
    margin-bottom: rem(15);
}

.editButtonsWrapper {
    column-gap: 10px;
    display: flex;
    width: 100%;
}

.editButton {
    flex: 1;
}
