@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    padding: 0 rem(9);

    &.small {
        padding: 0 rem(5);
    }

    &.disabled {
        opacity: 0.5;
    }
}

.arrowBox {
    @include border-radius(sm);
    align-items: center;
    background-color: var(--color-primary-5);
    display: flex;
    height: rem(18);
    justify-content: center;
    pointer-events: none;
    position: relative;
    width: rem(18);
}

.arrow {
    color: var(--color-accent);
    flex: 0 0 rem(16);
    height: rem(16);
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
    width: rem(16);

    .expanded & {
        transform: rotate(-180deg);
    }
}
