@import "@danishagro/shared/src/styles/helpers/all";

.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0 15px;

    @include mq(xs) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
}

.appendage {
    font-size: rem(12);

    @include mq(xs) {
        text-align: right;
    }
}
