@import "@danishagro/shared/src/styles/helpers/all";

.accordion {
    --accordion-toggle-color: var(--color-primary-1);
    --accordion-border: rem(1) solid var(--color-grey-20);
    border-top: rem(1) solid var(--color-grey-20);
}

.split {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: rem(30);
    margin-bottom: rem(30);
    width: 100%;

    @include mq(md) {
        flex-direction: row;
    }
}

.infobox {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    padding: rem(30);
    width: 100%;

    h3 {
        margin-bottom: 10px;
    }
}

.infoboxInner {
    display: grid;
    gap: rem(30);
    grid-template-columns: 1fr;
    width: 100%;

    strong {
        display: block;
        margin-bottom: 20px;
    }

    @include mq(lg) {
        gap: rem(100);
        grid-template-columns: 1.5fr 1fr;
    }
}

.orderlinesWrapper {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    margin-bottom: rem(30);
    padding: rem(30);
}

.orderLinesHeader {
    margin-bottom: rem(15);

    @include mq(sm) {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }

    strong {
        display: block;
        margin-bottom: 5px;
    }
}

.orderlines {
    border-top: 1px solid var(--color-grey-20);
}

.summaryWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.summary {
    margin-bottom: rem(30);
    max-width: rem(450);
    width: 100%;
}

.shippingInfo {
    .info {
        display: block;
        line-height: 25px;
    }
}

.driverMessage {
    font-size: rem(12);
    margin-top: 25px;
}
