@import "@danishagro/shared/src/styles/helpers/all";

.mainInfo {
    align-items: center;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    @include mq(sm) {
        flex: 0 0 38%;
        gap: 0;
        margin: 0;
        order: 1;
        padding-right: 10px;
    }
}

.imageWrapper {
    flex: 0 0 60px;
    height: 60px;
    text-align: center;
    width: 60px;

    @include mq(sm) {
        flex-basis: 125px;
        height: auto;
        padding: 0 20px;
        width: 125px;
    }

    .dimmed & {
        opacity: 0.5;
    }
}

.imageLink {
    display: inline-block;
}

.image {
    height: auto;
    max-height: 60px;
    vertical-align: bottom;
    width: auto;

    @include mq(sm) {
        max-height: none;
    }
}

.numberAndName {
    flex: 1 1 auto;
}

.name {
    font-size: rem(12);

    @include mq(sm) {
        font-size: rem(14);
    }
}

.nameLink {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.number {
    display: flex;
    font-size: rem(10);

    @include mq(sm) {
        font-size: rem(12);
    }

    @include mq(sm) {
        display: grid;
    }

    @include mq(md) {
        display: flex;
    }

    .dimmed & {
        opacity: 0.5;
    }
}

.actions {
    align-items: center;
    display: flex;
    gap: 25px;

    @include mq(sm) {
        flex: 0 0 35%;
        order: 3;
    }
}

.spinner {
    flex: 0 0 auto;
    height: rem(35);
    padding: rem(10);
    width: rem(35);

    @include mq(sm) {
        margin-right: 20px;
    }
}

.removeButton {
    background: transparent;
    border: 0;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 0;
    height: rem(35);
    width: rem(35);

    @include mq(sm) {
        margin-right: 20px;
    }
}

.removeIcon {
    color: var(--color-grey-80);
    height: rem(20);
    width: rem(20);

    &.disableDeleteButton {
        color: var(--color-grey-20);
        cursor: not-allowed;
    }
}

.total {
    font-weight: bold;
    margin: 0 0 0 auto;
    text-align: right;

    @include mq(sm) {
        font-weight: 700;
        order: 4;
    }

    .dimmed & {
        opacity: 0.5;
    }
}

.totalLabel {
    font-weight: normal;

    @include mq(sm) {
        display: none;
    }
}

.charges {
    color: var(--color-grey-60);
    font-size: rem(10);
    margin-top: 2px;
}

.deliveryDate {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: rem(12);
    gap: 10px;
    margin-top: 10px;

    @include mq(sm) {
        align-self: center;
        flex: 0 0 25%;
        flex-direction: row;
        font-size: rem(14);
        margin: 0;
        order: 2;
        padding-right: 10px;
    }

    .dimmed & {
        opacity: 0.5;
    }
}

.dateLabel {
    @include mq(sm) {
        display: none;
    }
}

.cartItemBox {
    display: flex;
    position: relative;
    width: 100%;

    &.hasDriverMessage {
        padding-bottom: 20px;
    }
}

.quantity {
    font-weight: 700;
}

.deliverShow {
    font-size: rem(10);

    @include mq(md) {
        display: block;
    }

    @include mq(lg) {
        display: block;
        font-size: rem(14);
    }
}

.deliverWrapperShow {
    display: block;

    @include mq(lg) {
        display: flex;
    }
}

.commentWrapper {
    align-items: center;
    display: flex;
    justify-content: center;

    @include mq(sm) {
        flex: 0 0 15%;
        order: 3;
    }
}

.driverMessage {
    background-color: var(--color-grey-10);
    bottom: 0;
    padding: rem(3) rem(10);
    position: absolute;
    width: 100%;
}

.extraFeesItem {
    display: block;
    font-weight: 400;
}

.extraFees {
    position: relative;
}

.tag {
    background-color: #fecad3;
    border-radius: 30px;
    color: var(--color-alert);
    font-size: rem(9);
    margin-left: 5px;
    padding: 1px 7px;
    padding-bottom: 2px;

    position: relative;
    top: 1px;

    width: fit-content;

    @include mq(sm) {
        margin-bottom: 5px;
    }

    @include mq(md) {
        margin-bottom: 0;
    }
}

.combinedDelivery {
    display: flex;
}

.combinedDeliveryIcon {
    height: 13px;
    margin-right: 5px;
    position: relative;
    top: 3px;
}

.combinedDeliveryText {
    color: var(--color-primary-1);
    font-size: rem(12);
}
