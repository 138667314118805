@import "@danishagro/shared/src/styles/helpers/all";

.linkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: rem(20);
}

.linkbox {
    background-color: var(--color-grey-10);
    flex: 1 0 calc(50% - #{rem(10)});
    padding: rem(20) rem(20) rem(15);
}

.linkTag {
    display: flex;
    margin-bottom: rem(15);
}
