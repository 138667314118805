@import "@danishagro/shared/src/styles/helpers/all";

.pickerBtn {
    @include clean-button;
    @include border-radius(sm);
    border: 1px solid var(--color-grey-40);
    display: flex;
    max-width: 100%;
    padding: 10px 10px;
    position: relative;
    text-align: inherit;
    vertical-align: baseline;
    width: 100%;
}

.pickerBtnLeftContent {
    position: relative;
}

.pickerBtnRightContent {
    display: flex;
    margin-left: auto;
    position: relative;
    top: 1px;
}

.varianCount {
    color: var(--color-grey-60);
    font-size: 12px;
}

.arrowIcon {
    div {
        padding-right: 0;
    }
}

.selectedOption {
    font-weight: 700;
}

.drawerButtonsWrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    position: relative;
}

.btnOption {
    @include clean-button;
    @include border-radius(sm);
    border: 1px solid var(--color-grey-10);
    color: var(--color-grey-80);
    display: block;
    margin-bottom: 10px;
    max-width: 100%;
    padding: 10px 10px;
    position: relative;
    text-align: left;
    width: 100%;
}

.selected {
    background-color: var(--color-primary-5);
}
