@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    background: var(--color-white);
    position: relative;
}

.item {
    padding: 20px 15px;

    @include mq(sm) {
        align-items: center;
        display: flex;
        padding: 5px 10px;
    }
}

.imageAndDataWrapper {
    align-items: center;
    display: flex;
    flex-basis: 45%;
}

.imageWrapper {
    flex: 0 0 60px;
    height: 60px;
    width: 60px;

    @include mq(sm) {
        flex-basis: 100px;
        height: 85px;
        padding-right: 15px;
        width: 100px;
    }
}

.image {
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.quantityWrapper {
    display: flex;
    flex-basis: 11%;
    min-width: 11%;
    position: relative;
}

.id {
    font-size: rem(10);

    @include mq(sm) {
        font-size: rem(12);
    }
}

.name {
    font-size: rem(12);

    @include mq(sm) {
        font-size: rem(14);
    }
}

.actions {
    flex-basis: 20%;
}

.removeButton {
    background: transparent;
    border: 0;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 0;
    height: rem(35);
    width: rem(35);
}

.removeIcon {
    color: var(--color-grey-80);
    height: rem(20);
    width: rem(20);
}

.total {
    font-weight: bold;
    margin: 0 0 0 auto;
    text-align: right;

    @include mq(sm) {
        font-weight: normal;
    }
}

.totalLabel {
    font-weight: normal;

    @include mq(sm) {
        display: none;
    }
}

// Mobile styling

.mobileImageAndData {
    align-items: center;
    display: flex;
}

.mobileImageWrapper {
    flex-basis: 15%;
    margin-right: rem(10);
}

.mobileFrequencyTitle {
    color: var(--color-grey-80);
    font-size: rem(14);
    font-weight: 600;
    margin-bottom: rem(5);
}

.mobileSelectWrapper {
    display: flex;
}

.mobileQuantityPriceWrapper {
    align-items: center;
    display: flex;
    margin-top: rem(20);
}

.saveIndicatorWrapper {
    align-items: center;
    display: flex;
    flex-basis: 5%;
    justify-content: center;
}

.saveIndicatorContent {
    color: var(--color-action);
    font-size: rem(13);
    height: rem(15);
    overflow: hidden;
    width: rem(15);
}

.iconWrapper {
    display: inline-block;
    height: 1em;
    width: 1em;
}

.nameLink {
    color: inherit;
    font-size: 12rem;
    text-decoration: none;

    @include mq(sm) {
        font-size: 14rem;
    }

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.labelTagWrapper {
    display: flex;
    margin-top: rem(10);

    @include mq(sm, sm-max) {
        display: none;
    }

    @include mq(sm) {
        flex-basis: 11%;
        justify-content: center;
        margin-top: 0;
    }
}

.labelTagWrapperAbsolute {
    display: none;

    @include mq(sm, md-max) {
        bottom: -20px;
        display: inline;
        position: absolute;
        width: 100px;
    }

    @include mq(md) {
        display: none;
    }
}
