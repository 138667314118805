@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    background: var(--color-white);
}

.item {
    border-bottom: 1px solid var(--color-grey-20);
    padding: 20px 10px;

    @include mq(sm) {
        display: flex;
        padding: 5px 10px;
    }
}

.mainInfo {
    align-items: center;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    @include mq(sm) {
        flex: 1 1 55%;
        gap: 0;
        margin: 0;
        min-width: 300px;
        order: 1;
        padding-right: 10px;
    }
}

.imageWrapper {
    flex: 0 0 60px;
    height: 60px;
    margin-bottom: rem(5);
    width: 60px;

    @include mq(sm) {
        flex-basis: 85px;
        height: 85px;
        margin-bottom: 0;
        width: 85px;
    }
}

.image {
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.numberAndName {
    flex: 1 1 auto;

    @include mq(sm) {
        align-self: center;
        padding-left: 15px;
    }
}

.number {
    font-size: rem(10);

    @include mq(sm) {
        font-size: rem(12);
    }
}

.name {
    font-size: rem(12);

    @include mq(sm) {
        font-size: rem(14);
    }
}

.actions {
    align-items: center;
    display: flex;
    gap: 10px;
    position: relative;

    &.includeFrequency {
        margin-top: -10px;
    }

    @include mq(sm) {
        flex: 0 0 15%;
        order: 3;
    }
}

.selectionWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    order: 10;

    @include mq(sm) {
        flex: 0 0 8%;
        order: 4;
    }

    &.isShown {
        visibility: visible;
    }

    &.isHidden {
        display: none;

        @include mq(sm) {
            display: flex;
            pointer-events: none;
            visibility: hidden;
        }
    }
}

.pricingWrapper {
    align-items: center;
    display: flex;

    @include mq(sm) {
        flex: 0 0 8%;
        order: 6;
    }
}

.spinner {
    flex: 0 0 auto;
    height: rem(35);
    padding: rem(10);
    width: rem(35);

    @include mq(sm) {
        margin-right: 20px;
    }
}

.removeButton {
    background: transparent;
    border: 0;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 0;
    height: rem(35);
    width: rem(35);

    @include mq(sm) {
        margin-right: 10px;
    }
}

.removeIcon {
    color: var(--color-grey-80);
    height: rem(20);
    width: rem(20);
}

.total {
    font-weight: bold;
    margin: 0 0 0 auto;
    text-align: right;

    @include mq(sm) {
        font-weight: normal;
    }
}

.totalLabel {
    font-weight: normal;

    @include mq(sm) {
        display: none;
    }
}

.frequency {
    bottom: 12px;
    color: var(--color-grey-60);
    font-size: 12px;
    position: absolute;
}

.mobileFrequency {
    color: var(--color-grey-60);
    font-size: rem(12);
    font-weight: 400;
    margin-top: rem(5);
}

.saveIndicatorWrapper {
    color: var(--color-action);
    font-size: rem(13);
    height: rem(15);
    overflow: hidden;
    width: rem(15);
}

.actionsWrapper {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;

    &.isTabletOrDesktop {
        display: contents;
    }
}

.nameLink {
    color: inherit;
    font-size: 12rem;
    text-decoration: none;

    @include mq(sm) {
        font-size: 14rem;
    }

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.labelTagWrapper {
    align-items: center;
    display: flex;

    @include mq(sm) {
        flex: 0 0 14%;
        order: 5;
    }

    &.mobileLabelTagWrapper {
        margin-top: rem(10);
    }
}
