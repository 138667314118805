@use "sass:color";
@use "sass:math";
@import "@danishagro/shared/src/styles/helpers/all";

.button {
    @include border-radius(sm);
    @include clean-button;
    align-items: center;
    backface-visibility: hidden;
    display: inline-flex;
    font-size: rem(14);
    font-weight: 600;
    letter-spacing: rem(0.3);
    min-height: rem(35);
    padding: rem(3) rem(15);
    position: relative;
    text-align: center;
    text-decoration: none;

    &::before {
        @include border-radius(sm);
        border: 1px solid transparent;
        content: "";
        inset: 0;
        position: absolute;
        transition: 0.2s ease-out;
        transition-property: filter, background-color;
        will-change: filter, background-color;
        z-index: 0;
    }

    &:not(.disabled):hover::before {
        filter: brightness(0.9);
    }

    &.largeText {
        font-size: rem(16);
    }

    &.small {
        min-height: rem(28);
        padding: rem(2) rem(12);
    }

    &.large {
        min-height: rem(44);
        padding: rem(10) rem(15);
    }

    &.fullWidth {
        width: 100%;
    }

    &.disabled {
        color: var(--color-grey-40);
        cursor: default;
        pointer-events: none;

        &::before {
            background-color: var(--color-grey-10);
        }
    }

    &.hasIcon {
        text-align: left;
    }

    &.iconOnly {
        padding-left: #{rem(math.div(35 - 18, 2))};
        padding-right: #{rem(math.div(35 - 18, 2))};
    }

    &.iconOnly.large {
        padding-left: rem(10);
        padding-right: rem(10);
    }

    &.ghost {
        &::before {
            background-color: transparent;
            border-color: currentcolor;
        }

        &.disabled {
            color: var(--color-grey-60);

            &::before {
                border-color: var(--color-grey-20);
            }
        }
    }

    &.noWrap {
        white-space: nowrap;
    }

    .content {
        align-items: center;
        display: inline-flex;
        flex: 0 1 auto;
        justify-content: space-between;
        margin: 0 auto;
        position: relative;
        transform: translateZ(0);
        will-change: contents;
        z-index: 1;
    }

    .spinner {
        left: 50%;
        line-height: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

        + .content {
            opacity: 0;
        }
    }

    &.iconRight {
        .content {
            flex-direction: row-reverse;
        }
    }
}

.title {
    flex: 1 1 100%;
    text-align: left;

    &:only-child {
        text-align: center;
    }
}

.icon {
    flex: 0 0 rem(18);
    height: rem(18);
    margin-right: rem(10);
    width: rem(18);

    .small & {
        flex-basis: rem(12);
        height: rem(12);
        margin-right: rem(5);
        width: rem(12);
    }

    .large & {
        flex-basis: rem(24);
        height: rem(24);
        margin-right: rem(10);
        width: rem(24);
    }

    .iconRight & {
        margin-left: rem(10);
        margin-right: 0;
    }

    .iconRight.small & {
        margin-left: rem(5);
    }

    .iconRight.large & {
        margin-left: rem(10);
    }

    &:only-child {
        margin: 0;
    }
}

.action {
    color: var(--color-white);

    &.ghost {
        color: var(--color-action);
    }

    &::before {
        background-color: var(--color-action);
    }
}

.alert {
    color: var(--color-white);

    &.ghost {
        color: var(--color-alert);
    }

    &::before {
        background-color: var(--color-alert);
    }
}

.white {
    color: var(--color-black);

    &.ghost {
        color: var(--color-white);
    }

    &::before {
        background-color: var(--color-white);
    }
}

.grey {
    color: var(--color-white);

    &.ghost {
        color: var(--color-grey-80);
    }

    &::before {
        background-color: var(--color-grey-80);
    }
}

.lightGrey {
    color: var(--color-grey-60);

    &.ghost {
        color: var(--color-grey-60);
    }

    &::before {
        background-color: var(--color-grey-5);
    }
}

.primary {
    color: var(--color-primary-1);

    &.ghost {
        color: var(--color-white);
    }

    &::before {
        background-color: var(--color-white);
    }
}

.acent {
    color: var(--color-accent);

    &.ghost {
        color: var(--color-white);
    }

    &::before {
        background-color: var(--color-white);
    }
}

.showSpinnerWithTitle {
    margin-right: 10px !important;
}
