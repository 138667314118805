@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    background: var(--color-white);
    max-width: 100%;
    width: 100%;

    @include mq(md) {
        @include border-radius(md);
        @include box-shadow(3);
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        right: 0;
        z-index: 2;
    }
}
