@import "@danishagro/shared/src/styles/helpers/all";

.alert {
    @include border-radius(md);
    align-items: center;
    display: flex;
    padding: 20px 15px;
}

.icon {
    flex-shrink: 0;
    height: rem(18);
    margin-right: 15px;
    width: rem(18);
}

.close {
    background: transparent;
    border: 0;
    color: currentcolor;
    cursor: pointer;
    flex-shrink: 0;
    height: rem(16);
    margin-left: auto;
    padding: 0;
    width: rem(16);
}

.alertText {
    margin-right: 10px;

    a {
        color: currentcolor;
    }
}

.shadow {
    @include box-shadow(2);
}

.small {
    padding: 0 15px;

    .alertText {
        padding: 5px 0;
    }

    .icon {
        margin-right: 10px;
    }
}

/*colors*/
.error {
    background: #fecad3;
    color: #a71b34;
}

.success {
    background: #e7ecc9;
    color: #426716;
}

.warning {
    background: #f8e8a6;
    color: #575757;
}

.info {
    background: #d0deeb;
    color: #103f6d;
}
