@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    padding: 25px 15px 0;

    @include mq(md) {
        padding: rem(25);
    }
}

.mobileWrapper {
    padding-bottom: 15px;
    padding-top: 15px;

    .header {
        margin-bottom: 0;
    }
}

.mobileBasketDetailsInner {
    & > * {
        margin-top: rem(30);
    }

    &.isCollapsed {
        display: none;
    }

    border-top: 1px solid var(--color-grey-20);
    margin-top: 15px;

    section {
        margin-top: 0;
    }
}

.itemWrapper {
    border-bottom: 1px solid var(--color-grey-20);
    display: block;
    margin-bottom: 30px;
    padding-bottom: 30px;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.infobox {
    h3 {
        margin-bottom: 10px;
    }
}

.infoboxInner {
    display: grid;
    gap: rem(30);
    grid-template-columns: 1fr;
    width: 100%;

    strong {
        display: block;
        margin-bottom: 20px;
    }

    @include mq(lg) {
        gap: rem(100);
        grid-template-columns: 1.5fr 1fr;
    }
}

.shippingInfo {
    .info {
        display: block;
        line-height: 25px;
    }

    .driverMessage {
        font-size: rem(12);
        margin-top: 25px;
    }
}

.orderlinesWrapper {
    margin-bottom: rem(30);
}

.orderLinesHeader {
    margin-bottom: rem(20);

    @include mq(sm) {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }

    strong {
        display: block;
        margin-bottom: 5px;
    }
}

.orderlines {
    border-top: 1px solid var(--color-grey-20);

    > div {
        box-shadow: none;
    }
}

.summaryWrapper {
    display: flex;
    width: 100%;
}

.summary {
    margin-bottom: rem(30);
    width: 100%;

    > div {
        border-radius: 0;
        box-shadow: none;
        padding: 0;
    }
}
