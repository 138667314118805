@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    user-select: none;
}

.labelElement {
    cursor: pointer;
    display: inline-block;

    .disabled & {
        cursor: default;
    }
}

.indicatorWrapper {
    position: relative;

    .horizontal & {
        top: rem(3);
    }
}

.input {
    appearance: none;
    margin: 0;
    pointer-events: none;
}

.indicator {
    background: var(--color-white);
    border: 1px solid var(--color-grey-80);
    border-radius: 50%;
    color: var(--color-white);
    padding: rem(1);

    input:checked + & {
        border: 5px solid var(--color-primary-1);
    }

    .vertical & {
        position: static;
    }

    .disabled & {
        background: var(--color-grey-5);
        border-color: var(--color-grey-40);
        color: var(--color-grey-40);
    }
}

.input,
.indicator {
    height: rem(15);
    left: 0;
    position: absolute;
    top: rem(0.25);
    vertical-align: top;
    width: rem(15);
    z-index: 2;

    .vertical & {
        left: calc(50% - #{rem(15)} / 2);
        margin: 0 auto 2px;
        top: 0;
    }
}

.label {
    display: inline-block;
    padding-left: rem(25);

    .disabled & {
        cursor: default;
    }

    .vertical & {
        padding: 0;
    }
}

.tooltip {
    display: inline-block;
    height: 0;
    vertical-align: top;
}

.smallPadding {
    padding-left: 20px;
}

.labelbold {
    font-weight: 700;
}
