@import "@danishagro/shared/src/styles/helpers/all";

.content {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;

    @include mq(sm) {
        flex-direction: column;
    }
}

.inputLine {
    column-gap: 10px;
    display: flex;
    margin-bottom: rem(10);
}

.buttons {
    display: flex;
    gap: rem(20);
    margin-top: rem(20);
    width: 100%;
}

.input {
    width: 100%;
}

.errorMessage {
    color: var(--color-alert);
    display: block;
    margin-top: rem(10);
}

.error {
    margin-top: 15px;

    @include mq(sm) {
        margin-top: 20px;
    }
}

.inputAddressButton {
    margin-top: 15px;
}

.removeButton {
    align-items: flex-end;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: 0;
    margin-bottom: 10px;
}

.removeIcon {
    color: var(--color-grey-80);
    height: rem(20);
    width: rem(20);
}
