@import "@danishagro/shared/src/styles/helpers/all";

.quickSelectTooltip {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    padding: rem(15);
    position: absolute;
    right: 0;
    top: 100%;
    width: fit-content;
    z-index: 5;
}

.quickSelectLink {
    color: var(--color-action);
    font-size: rem(14);
    font-weight: 400;
    white-space: nowrap;
}
