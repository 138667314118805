@import "@danishagro/shared/src/styles/helpers/all";

.enlargeButton {
    @include clean-button;
    @include border-radius(sm);
    align-items: center;
    background-color: var(--color-grey-5);
    color: var(--color-grey-60);
    display: flex;
    height: rem(35);
    justify-content: center;
    transition: background-color 0.2s ease-out;
    width: rem(35);
    z-index: 2;

    @include mq(sm) {
        height: rem(45);
        width: rem(45);
    }

    &:hover {
        background-color: var(--color-grey-10);
    }
}

.iconSize {
    width: rem(14);

    @include mq(sm) {
        width: rem(20);
    }
}
