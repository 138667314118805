@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    user-select: none;
}

.labelElement {
    cursor: pointer;
    display: inline-block;

    .disabled & {
        cursor: default;
    }
}

.labelInside {
    padding: rem(4) 0;
    position: relative;

    @include mq(md) {
        padding: 0;
    }
}

.input {
    appearance: none;
    margin: 0;
    pointer-events: none;
}

.input,
.indicator {
    height: rem(30);
    left: 0;
    position: absolute;
    top: 0;
    width: rem(30);
    z-index: 2;

    @include mq(md) {
        height: rem(15);
        top: rem(2);
        width: rem(15);
    }
}

.mobileSize {
    label {
        width: 100%;
    }

    .input,
    .indicator {
        height: rem(15);
        padding: rem(1);
        top: rem(3);
        width: rem(15);
    }

    .label {
        padding-left: rem(20);
    }
}

.indicator {
    @include border-radius(sm);
    background: var(--color-white);
    border: 1px solid var(--color-grey-60);
    color: var(--color-white);
    padding: rem(5);

    @include mq(md) {
        padding: rem(1);
    }

    .disabled & {
        background: var(--color-grey-5);
        border-color: var(--color-grey-40);
        color: var(--color-grey-40);
    }

    &.colored {
        background: var(--color-primary-1);
        border-color: transparent;
    }

    &.redBackground {
        background: var(--color-alert);
    }
}

.checkmarkIcon {
    vertical-align: top;
}

.indeterminateMark {
    background: currentcolor;
    height: rem(2);
    left: rem(2);
    position: absolute;
    right: rem(2);
    top: 50%;
    transform: translateY(#{rem(-2)});
}

.label {
    display: inline-block;
    padding-left: rem(40);

    @include mq(md) {
        padding-left: rem(25);
    }

    .disabled & {
        opacity: 0.75;
    }

    a {
        @include link-style();
    }
}

.isSpecialCheckboxLabel {
    width: 100%;

    .labelInside {
        width: 100%;

        input {
            left: unset;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.specialCheckbox {
    @include border-radius(sm);
    border: 1px solid var(--color-grey-20);
    padding: 10px;

    .indicator {
        left: unset;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .label {
        padding: 5px;
    }

    .specialIcon {
        svg {
            position: relative;
            top: 2px;
        }
    }
}

.specialCheckboxChecked {
    background-color: var(--color-primary-6);
    border: 1px solid var(--color-primary-1);
}
