@import "@danishagro/shared/src/styles/helpers/all";

.list {
    list-style: none;
    margin: 0;
    padding: rem(5);
}

.item {
    display: flex;
}

.link {
    padding: rem(5);
}
