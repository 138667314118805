@import "@danishagro/shared/src/styles/helpers/all";

.buttonWrapper {
    display: flex;
    gap: 15px;
    margin-top: 15px;

    & * {
        flex: 1;
    }
}
