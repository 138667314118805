@import "@danishagro/shared/src/styles/helpers/all";

.vetMedicationLink {
    bottom: 15px;
    position: fixed;
    right: 15px;
    z-index: 2;

    @include mq(sm-max) {
        bottom: calc($toolbar-height + env(safe-area-inset-bottom) + 65px);
    }
}

.vetMedicationImage {
    max-width: 80px;
    vertical-align: bottom;

    @include mq(sm) {
        max-width: none;
    }
}

.vetMedicationLinkFrontPage {
    bottom: calc($toolbar-height + env(safe-area-inset-bottom) + 5px);

    @include mq(md) {
        bottom: 15px;
    }
}
