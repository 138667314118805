@import "@danishagro/shared/src/styles/helpers/all";

.expandButton {
    @include clean-button;
    margin: 20px 0;
    padding-left: 25px;
    position: relative;
    text-align: left;

    @include mq(sm) {
        margin: 10px 0;
    }
}

.arrow {
    color: var(--color-action);
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: 20px;

    &.rotated {
        transform: translate3d(0, -50%, 0) rotate(180deg);
    }
}

.cart {
    overflow: hidden;

    &.expanded {
        flex-grow: 1;
        overflow-y: scroll;

        @include mq(sm) {
            overflow-y: hidden;
        }
    }
}

.cartHeader {
    display: none;

    @include mq(sm) {
        align-items: center;
        background: var(--color-grey-10);
        display: flex;
        font-weight: 700;
        padding: 5px;
    }
}

.cartHeaderItem {
    flex: 0 0 auto;
    padding-right: 10px;

    &:nth-child(1) {
        flex-basis: 65%;
        padding-left: 10px;
    }

    &:nth-child(2) {
        flex-basis: 20%;
        padding-left: 10px;
        text-align: right;
    }

    &:nth-child(3) {
        flex-basis: 15%;
        padding: 0 25px 0 0;
        text-align: right;
    }
}

.cartItemsWrapper {
    display: flex;
    flex-direction: column;

    @include mq(sm) {
        align-items: center;
        max-height: 150px;
        overflow-y: scroll;
    }
}

.itemWrapper {
    align-items: center;
    border-bottom: 1px solid var(--color-grey-10);
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    width: 100%;

    @include mq(sm) {
        border-bottom: 0;
        flex-wrap: nowrap;
    }
}

.imageNameWrapper {
    display: flex;
    flex-basis: 100%;

    @include mq(sm) {
        flex-basis: 65%;
    }
}

.imageWrapper {
    align-items: center;
    display: flex;
    flex: 0 0 60px;
    justify-content: center;

    & > img {
        height: 60px;
        object-fit: contain;
        width: 60px;
    }
}

.productName {
    align-items: center;
    display: flex;
    font-weight: 400;
    padding-left: 10px;

    @include mq(sm) {
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 15px;
    }
}

.quantity {
    display: flex;
    flex-basis: 50%;
    padding-left: 70px;

    @include mq(sm) {
        flex-basis: 25%;
        justify-content: flex-end;
        padding-left: 0;
        text-align: right;
    }
}

.price {
    align-items: center;
    display: flex;
    flex-basis: 50%;
    font-weight: 600;
    justify-content: flex-end;
    padding-right: 70px;

    @include mq(sm) {
        flex-basis: 15%;
        padding-right: 10px;
    }
}

.buttonWrapper {
    border-top: 1px solid var(--color-grey-10);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 10px;

    @include mq(sm) {
        border-top: 0;
        flex-wrap: nowrap;
    }
}

.primaryButton {
    flex: 1 1 100%;
    margin-bottom: 10px;

    @include mq(sm) {
        flex: 0 0 auto;
        margin-right: 15px;
    }
}

.promptContent {
    padding-left: 7px;
    padding-right: 7px;

    .buttonWrapper {
        margin-left: 7px;
        margin-right: 7px;
    }
}
