@import "@danishagro/shared/src/styles/helpers/all";

.cartList {
    overflow: hidden;

    @include mq(xs-max) {
        background-color: #fff;
        display: grid;
        gap: 15px;
        padding: 15px 10px;
    }

    &.withShadow {
        @include border-radius(md);
        @include box-shadow(1);
    }
}

.header {
    display: none;

    @include mq(sm) {
        align-items: center;
        background: var(--color-grey-10);
        display: flex;
        font-weight: 700;
        padding: 5px 25px 5px 0;
    }
}

.headerItem {
    flex: 0 0 auto;
    padding-right: 10px;

    &:first-child {
        flex-basis: 38%;
        padding-left: 125px;
    }

    &:nth-child(2) {
        flex-basis: 25%;
        flex-shrink: 1;
    }

    &:nth-child(3) {
        flex-basis: 155px;
    }

    &:nth-child(4) {
        flex-basis: 15%;
        text-align: center;
    }

    &:last-child {
        margin-left: auto;
        margin-right: 2%;
        padding: 0;
        text-align: right;
    }
}

.item {
    border-bottom: 1px solid var(--color-grey-20);
    overflow: hidden;
    position: relative;

    @include mq(sm) {
        &:last-child {
            border-bottom-width: 0;
        }
    }
}

.checkItem {
    color: var(--color-alert);
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.quickSelectWrapper {
    position: absolute;
    right: 0;
    top: 13px;

    &.mobileOrTablet {
        margin-bottom: 10px;
    }

    &.mobilePosition {
        cursor: not-allowed;
        pointer-events: none;
    }
}

.quickSelectTogggleButton {
    align-items: center;
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;

    &.mobilePositionButton {
        height: auto;
        position: absolute;
        right: 0;
        top: 15px;
    }
}

.quickSelectTogggleIcon {
    height: 16px;
    width: 16px;
}

.controlsWrapper {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    &.mobile {
        margin: 15px 0;
    }
}

.quickSelectLink {
    color: var(--color-action);
    font-size: rem(14);
    font-weight: 400;
    white-space: nowrap;
}

.buttonCancel {
    color: var(--color-primary-1);
}
