@import "@danishagro/shared/src/styles/helpers/all";

.input {
    @include placeholder(var(--color-grey-40));
    @include border-radius(sm);
    background: var(--color-white);
    border: 1px solid var(--color-grey-40);
    box-shadow: inset 0 0 0 1px transparent;
    font: inherit;
    font-size: max(rem(16), 16px); // Must be at least 16px to avoid iOS zooming in
    height: rem(40);
    outline: none;
    padding: 0 rem(5) 0 rem(10);
    transition: 100ms;
    transition-property: border-color, box-shadow;
    width: 100%;

    &:read-only,
    &:disabled {
        background-color: var(--color-grey-5);
        color: var(--color-grey-40);
        cursor: default;
        pointer-events: none;
    }

    &:not(:disabled):hover,
    &:focus {
        border-color: var(--color-primary-1);
    }

    &:focus {
        box-shadow: inset 0 0 0 1px var(--color-primary-1);
    }

    &.textarea {
        min-height: rem(35);
        padding-bottom: rem(5);
        padding-top: rem(6);
    }

    &.invalid {
        border-color: var(--color-alert);
        box-shadow: inset 0 0 0 1px var(--color-alert);
    }

    &.highlighted {
        border-color: var(--color-primary-1);
    }
}
