@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    font-size: rem(14);
    transition: opacity 0.25s ease;

    &.alignCenter {
        text-align: center;
    }

    &.bold {
        font-weight: 600;
    }

    &.dimmed {
        opacity: 0.5;
    }

    &.paddingBottom {
        padding-bottom: rem(25);
    }

    &.largeText {
        font-size: rem(16);
        line-height: var(--line-height, #{rem(25)});
    }

    &.smallText {
        font-size: rem(12);
    }

    a {
        @include link-style();
    }

    > p,
    > ul,
    > ol {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    hr {
        background: transparent;
        border: 0;
        border-bottom: 1px solid var(--color-grey-20);
        height: 0;
        margin: 20px 0;
        padding: 0;
    }

    ul,
    ol {
        list-style: none;
        padding: 0;

        li {
            margin: 0;
            padding-left: 1em;

            &::before {
                content: "\2022";
                display: inline-block;
                margin-left: -1em;
                text-align: left;
                width: 1em;
            }
        }
    }

    ol {
        counter-reset: number;

        li {
            counter-increment: number;

            &::before {
                content: counter(number) ".";
                width: auto;
            }
        }
    }
}
