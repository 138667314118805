@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: rem(10);
    text-align: center;

    &.fadeIn {
        animation: fade-in 0.4s 0.4s forwards;
        opacity: 0;
    }
}

.iconWrapper {
    align-items: center;
    // background-color: var(--color-grey-10);
    border: 3px solid var(--color-action);
    border-radius: 100%;
    display: flex;
    font-size: rem(30);
    height: rem(80);
    justify-content: center;
    padding: rem(15);
    text-align: center;
    width: rem(80);

    .iconOnly & {
        background-color: transparent;
        // padding: rem(5);
    }
}

.icon {
    color: var(--color-action);
    height: 100%;
    width: 100%;
}

.emptyTitle {
    margin: 20px 0 10px;

    .iconOnly & {
        margin: 10px 0;
    }
}

.text {
    @include mq(md) {
        max-width: max(50%, 420px);
    }
}

.cta {
    margin-top: rem(15);
}

@keyframes fade-in {
    to {
        opacity: 100%;
    }
}
