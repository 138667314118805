@import "@danishagro/shared/src/styles/helpers/all";

.header {
    background: var(--color-white);
    color: var(--color-grey-90);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 900;

    //Styling for print
    @media print {
        border-bottom: 0;
        position: relative;
        top: 0;
    }

    @include mq(sm) {
        border-bottom: 1px solid var(--color-grey-20);
    }
}

.serviceArea {
    background: var(--color-grey-5);
}

.serviceAreaFlex {
    display: flex;
    justify-content: space-between;
}

.navigationWrapper {
    align-items: center;
    background-color: var(--color-white);
    display: flex;
    position: relative;

    @include mq(md) {
        align-items: normal;
        flex-direction: column;
        padding-top: 15px;
    }
}

.logoWrapper {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-right: auto;
    width: 100%;

    svg {
        width: fit-content;
    }
}

.logo {
    display: inline-block;
    height: 46px;
    padding: 5px 0 8px;

    @include mq(sm) {
        height: 80px;
        padding: 10px 0;
    }

    svg {
        height: 100%;
    }
}

.search {
    padding-bottom: 10px;
}

.skipToMainContentLink {
    background-color: var(--color-action);
    color: var(--color-white);
    left: 50%;
    padding: 10px;
    position: absolute;
    transform: translate(-50%, -100%);
    transition: transform 0.3s;
    z-index: 2;

    &:not(:focus) {
        opacity: 0;
    }
}

.skipToMainContentLink:focus {
    transform: translate(-50%, 0);
}

.serviceRight {
    display: flex;
}

.logOutLink {
    position: relative;
    top: 2px;
}

.buttonLink {
    align-items: center;
    color: inherit;
    display: inline-flex;
    padding: 3px 0;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
    }
}

.buttonIcon {
    fill: currentcolor;
    flex: 1 1 rem(18);
    height: rem(18);
    width: rem(18);
}

.buttonText {
    display: inline-block;
    flex: 1 1 auto;
    padding-left: 10px;
}
