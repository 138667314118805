@import "@danishagro/shared/src/styles/helpers/all";

.videoInner {
    aspect-ratio: 16 / 9;
    width: 100%;
}

.posterImage {
    position: relative;
}

.posterOverlay {
    position: relative;

    @include mq(sm-max) {
        height: 100%;
        width: max-content;

        img {
            height: 100%;
        }
    }
}
