@import "@danishagro/shared/src/styles/helpers/all";

.list {
    align-items: center;
    background: var(--color-grey-5);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    height: 100vh;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    user-select: none;
    width: 110px;

    @include mq(md) {
        background: transparent;
        flex-direction: row;
        height: auto;
        min-height: 30px;
        width: auto;
    }
}

.item {
    border-bottom: 1px solid var(--color-grey-20);
    display: block;
    font-weight: bold;
    width: 100%;

    @include mq(md) {
        border-bottom: 0;
        font-weight: normal;
        margin-left: 30px;
        width: auto;

        &:first-child {
            margin-left: 0;
        }
    }
}

.link {
    background: transparent;
    color: var(--color-grey-60);
    display: block;
    font-size: rem(12);
    padding: 20px 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.15s ease-out;
    width: 100%;

    @include mq(md) {
        height: 100%;
        padding: 3px 0;

        &:not(.isActive):hover {
            color: var(--color-black);
        }
    }

    &.isActive {
        background: var(--color-action);
        color: var(--color-white);

        @include mq(md) {
            background: transparent;
            border-bottom-color: var(--color-grey-90);
            color: var(--color-grey-90);
            font-weight: 600;
        }
    }
}
