@import "@danishagro/shared/src/styles/helpers/all";

$input-width-desktop: 280px;
$search-results-width-desktop: 430px;

.wrapper {
    padding-bottom: env(keyboard-inset-height);
    position: relative;
}

.form {
    position: relative;
}

.input {
    @include border-radius(sm);
    @include placeholder(var(--color-grey-60));
    appearance: none;
    background-color: var(--color-white);
    border: 1px solid var(--color-action);
    box-shadow: inset 0 0 0 1px transparent;
    color: var(--color-grey-60);
    font-size: rem(16);
    height: rem(30);
    outline: none;
    padding: 0 rem(30) 0 10px;
    transition: border-color 0.1s ease-out, box-shadow 0.1s ease-out, width 0.4s ease-in-out;
    width: 100%;
    will-change: border-color, box-shadow, width;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        appearance: none;
    }

    .wrapper:hover &,
    .wrapper:focus-within & {
        border-color: var(--color-accent);
        color: var(--color-grey-90);
    }

    &:focus {
        box-shadow: inset 0 0 0 1px var(--color-accent);
    }

    @include mq(md) {
        width: $input-width-desktop;

        &:not(:placeholder-shown),
        .form:focus-within & {
            width: $search-results-width-desktop;
        }
    }
}

.button {
    @include clean-button;
    bottom: 0;
    font-size: 0;
    height: rem(30);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: color 0.15s ease-out;
    width: rem(30);
}

.searchIcon {
    --icon-color: var(--color-action);
    height: rem(15);
    margin-top: rem(-1);
    width: rem(15);
}

.clearIcon {
    height: rem(12);
    width: rem(12);
}

.incrementalSearchResults {
    position: relative;
}

.resultsContainer {
    background-color: var(--color-white);
    padding: 20px 0;
    width: 100%;

    @include mq(md) {
        @include border-radius(md);
        @include box-shadow(3);
        padding: 10px 15px 15px;
        position: absolute;
        right: 0;
        top: 0;
        width: $search-results-width-desktop;
        z-index: 2;
    }
}

.statusContainer {
    align-items: center;
    background-color: var(--color-white);
    color: var(--color-grey-60);
    display: flex;
    justify-content: center;
    padding: 20px 0;
    text-align: center;
    width: 100%;

    @include mq(md) {
        @include border-radius(md);
        @include box-shadow(3);
        padding: 10px 15px 10px;
        position: absolute;
        right: 0;
        top: 0;
        width: $search-results-width-desktop;
        z-index: 2;
    }
}

.productList {
    margin: 5px 0 0;

    li {
        margin: 0;
    }

    &,
    li {
        list-style: none;
        padding: 0;
    }
}
