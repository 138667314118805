@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    display: inline-block;
    font-size: 0;

    svg {
        vertical-align: inherit;
    }

    &.fullSize {
        svg {
            height: 100%;
            width: var(--link-icon-width, 100%);
        }
    }
}
