@import "@danishagro/shared/src/styles/helpers/all";

.tooltipWithChildren {
    position: relative;
}

.tooltipButton {
    @include clean-button;
    font-size: 0;

    &.onTopOfChildren {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }

    &.standAlone {
        display: inline-block;
        padding: rem(5);
    }
}

.popUp {
    @include border-radius(sm);
    background: var(--color-grey-90);
    color: var(--color-white);
    font-size: rem(14);
    max-width: 90vw;
    padding: 10px;
    position: relative;

    @include mq(sm) {
        max-width: 300px;
    }
}

.icon {
    color: var(--color-grey-60);
    height: rem(12);
    width: rem(12);
}

.arrow {
    border-radius: 2px;
    height: 10px;
    position: absolute;
    visibility: hidden;
    width: 10px;
    z-index: 1;

    &::before {
        background: var(--color-grey-90);
        content: "";
        display: block;
        height: 100%;
        transform: rotate(45deg);
        visibility: visible;
    }

    [data-placement^="top"] > & {
        bottom: -5px;

        &::before {
            border-bottom-right-radius: 2px;
        }
    }

    [data-placement^="bottom"] > & {
        top: -5px;

        &::before {
            border-top-left-radius: 2px;
        }
    }

    [data-placement^="left"] > & {
        right: -5px;

        &::before {
            border-top-right-radius: 2px;
        }
    }

    [data-placement^="right"] > & {
        left: -5px;

        &::before {
            border-bottom-left-radius: 2px;
        }
    }
}

.textAlignLeft {
    text-align: left;
}

.textAlignCenter {
    text-align: center;
}

.textAlignRight {
    text-align: right;
}
