@import "@danishagro/shared/src/styles/helpers/all";

.swiperWrapper {
    position: relative;
    user-select: none;

    @include mq(sm) {
        margin: -10px;
        overflow: hidden;
        padding: 10px 19px;
    }

    @include mq(md) {
        margin: -10px -20px;
    }

    :global(.swiper) {
        overflow: visible;
    }

    :global(.swiper-pagination) {
        bottom: 0;
        margin-top: rem(20);
        position: relative;
        top: auto;

        @include mq(sm) {
            display: none;
        }
    }

    :global(.swiper-pagination-progressbar) {
        background: var(--color-grey-10);
    }

    :global(.swiper-pagination-progressbar-fill) {
        background: var(--color-grey-40);
    }
}

.navigationWrapper {
    display: flex;
    height: 100%;
    opacity: 0;
    position: relative;
    top: 0;
    transition: opacity 0.4s ease-out;
    z-index: 1;

    &.visible {
        opacity: 1;
    }

    &.previous {
        justify-content: flex-start;
        left: -9px;
        pointer-events: auto;

        @include mq(md) {
            left: -19px;
        }
    }

    &.next {
        justify-content: flex-end;
        margin-left: auto;
        pointer-events: auto;
        right: -9px;

        @include mq(md) {
            right: -19px;
        }
    }

    .buttonNav {
        @include clean-button;
        display: flex;
        height: 36px;
        width: 36px;

        button {
            width: 36px;

            &::before {
                background-color: var(--color-white);
            }
        }

        svg {
            width: rem(11);
        }
    }
}

.sliderTopWrapper {
    display: flex;
    margin-bottom: 15px;
    position: relative;
}

.navControl {
    display: flex;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 3;

    @include mq(xs-max) {
        display: none;
    }
}

.swiperWrapperInner {
    position: relative;
}
