@import "@danishagro/shared/src/styles/helpers/all";

.resultsHeader {
    align-items: flex-end;
    cursor: default;
    display: grid;
    // grid-template-columns: 75% 25%;
    // grid-template-columns: 70% 30%;

    grid-template-columns: 3fr max-content;

    // display: flex;
    height: rem(39);
    justify-content: space-between;
    padding-bottom: rem(15);
    user-select: none;

    .sorting {
        margin-left: 1vw;
    }

    .totalResults {
        font-weight: 400;
        position: relative;
        top: 6px;
    }
}

.categoryFilter {
    display: block;
}

.sortingFilter {
    display: flex;

    @include mq(sm) {
        margin-left: 15px;
    }
}

.selectedFilters {
    align-items: center;
    color: var(--color-grey-60);
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
    top: -1px;
}

.removeAllBtn {
    margin-left: auto;
    margin-right: 10px;
}
