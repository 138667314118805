@import "../../../styles/helpers/all";

.container {
    margin-bottom: -35px;

    @include mq(sm) {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
}

.text {
    padding-top: 15px;

    @include mq(sm) {
        flex: 1 1 100%;
        padding: 0 20px 75px 0;
    }
}

.image {
    text-align: center;

    @include mq(sm) {
        flex: 0 0 auto;
        max-width: 50%;
    }

    img {
        aspect-ratio: 17 / 16;
        max-width: 280px;
        vertical-align: bottom;
        width: 100%;

        @include mq(sm) {
            max-width: 527px;
        }
    }
}
