@import "@danishagro/shared/src/styles/helpers/all";

.filterDrawerContainer {
    @include border-radius(md);
    @include box-shadow(2);
    background-color: var(--color-white);
    bottom: calc(env(safe-area-inset-bottom) + 60px);
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    position: sticky;
    z-index: 9;
}

.orderLinesItemBtn {
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-80);
    border-radius: var(--border-radius-small);
    color: var(--color-grey-80);
    display: inline-flex;
    font-family: $font-family;
    font-weight: 600;
    justify-content: center;
    text-align: center;

    &:disabled {
        border: 1px solid var(--color-grey-20);
        color: var(--color-grey-20);
    }
}

.badge {
    align-content: center;
    background-color: var(--color-primary-1);
    border-radius: 100px;
    color: var(--color-white);
    display: grid;

    height: 17px;
    justify-content: center;
    margin-left: 5px;
    position: relative;
    width: 17px;
}

.badgeNumber {
    display: inline-block;
    font-size: rem(10);
}

.facets {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 0;
}

.drawerContentContainer {
    position: relative;
}

.drawerButtonsWrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 3fr;
}

.sorting {
    display: block;
}
