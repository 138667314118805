@import "@danishagro/shared/src/styles/helpers/all";

.wishListButton {
    @include border-radius(sm);
    align-items: center;
    background-color: var(--color-grey-5);
    border: none;
    cursor: pointer;
    display: flex;
    height: rem(45);
    justify-content: center;
    padding: rem(7);
    transition: background-color 0.2s ease-in-out;
    width: rem(45);
    z-index: 2;

    &:hover {
        background-color: var(--color-grey-10);

        span {
            background-color: var(--color-grey-10);
        }
    }

    > .wishListButton {
        color: var(--color-grey-80);
        height: rem(45);
        padding: rem(0);
        width: rem(45);
    }
}

.wishListBox {
    position: relative;
}

.wishListBoxTitle {
    display: flex;
    justify-content: center;

    svg {
        margin-right: 5px;
        position: relative;
        top: -1px;
        width: 15px;
    }
}

.wishListBoxBtns {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
}

.wishlistBtn {
    @include clean-button;
    border-bottom: 1px solid var(--color-primary-3);
    color: var(--color-action);
    display: flex;
    font-size: rem(12);
    margin: auto;
    width: max-content;

    svg {
        margin-right: 2px;
        position: relative;
        top: 3px;
        width: 10px;
    }
}
