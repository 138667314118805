@import "@danishagro/shared/src/styles/helpers/all";

.imageLink {
    @include box-shadow(1);
    @include border-radius;
    background-color: var(--color-white);
    color: inherit;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    width: 100%;
}

.imageWrapper {
    display: flex;
}

.limitedHeight {
    .image {
        @include mq(md) {
            max-height: 250px;
        }
    }
}

.image {
    @include border-radius(md);

    aspect-ratio: 4/3;
    background: var(--color-grey-10);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 0;
    object-fit: cover;
    width: 100%;
}

.bottomInfo {
    display: flex;
    padding: 10px;

    .arrow {
        @include border-radius(md);
        background-color: var(--color-primary-1);
        color: var(--color-white);
        height: 18px;
        margin-left: auto;
        position: relative;
        width: 18px;

        svg {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: rem(10);
        }
    }
}
