@import "@danishagro/shared/src/styles/helpers/all";

.collectedDelivery {
    margin-top: 20px;
    position: relative;
}

.container {
    position: relative;
}

.collectedDeliveryItem {
    display: grid;
    gap: 10px;
    grid-template-columns: 2fr 1fr;

    @include mq(sm-max) {
        display: block;
    }
}

.collectedDeliveryItemText {
    align-content: center;
}

.collectedDeliveryItemSwitch {
    align-content: center;
    margin-left: auto;

    @include mq(sm-max) {
        margin-top: 20px;
    }
}

.collectedDeliveryItemSwitchText {
    margin-right: 10px;
    position: relative;
    top: -7px;
}

.collectedDeliveryItemTextMarked {
    color: var(--color-primary-1);
    margin-right: 5px;
}

.collectedDeliveryItemTextInfo {
    position: relative;

    @include mq(sm-max) {
        display: block;
    }
}

.collectedDeliveryItemIcon {
    height: 13px;
    margin-right: 5px;
    position: relative;
    top: 3px;
}

.collectedDeliverySkeleton {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
}

.collectedDeliverySkeletonRight {
    position: relative;
    text-align: right;
}
