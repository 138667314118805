@import "../helpers/variables/colors";

@each $site, $colors in map-get($palette, site-specific) {
    .theme--#{$site} {
        @each $name, $color in $colors {
            --color-#{$name}: #{$color};
        }
    }
}

:root {
    @each $name, $color in map-get($palette, common) {
        --color-#{$name}: #{$color};
    }
}
