@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    @include border-radius(md);
    @include box-shadow(3);
    background: var(--color-white);
    overflow: auto;
    position: absolute;
    right: 0;
    z-index: 2;
}

.inside {
    background: var(--color-white);
    padding: 0 15px;

    @include mq(md) {
        padding: 0 20px;
    }

    @include mq(lg) {
        padding: 0 30px;
    }
}

.top {
    border-bottom: 1px solid var(--color-grey-20);
    padding: rem(10) 0;

    @include mq(md) {
        align-items: center;
        display: flex;
    }

    .hasMultipleCompanies & {
        @include mq(sm) {
            padding: 15px 0;
        }
    }
}

.userName {
    font-weight: bold;

    @include mq(md) {
        flex: 1 1 100%;
    }
}

.buttons {
    user-select: none;

    @include mq(md) {
        display: flex;
        flex: 0 0 auto;
        margin: 0 0 0 auto;
    }
}

.buttonWrapper {
    margin-top: 5px;

    @include mq(md) {
        flex: 0 0 auto;
        margin: 0;
        padding-left: 40px;
    }
}

.buttonLink {
    align-items: center;
    color: inherit;
    display: inline-flex;
    padding: 3px 0;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
    }
}

.buttonIcon {
    fill: currentcolor;
    flex: 1 1 rem(18);
    height: rem(18);
    width: rem(18);
}

// .buttonIconWrapper {
//     height: rem(18);
//     position: relative;
//     width: rem(18);
// }

// .buttonIconBadge {
//     bottom: rem(-2);
//     position: absolute;
//     right: rem(-6);
// }

.buttonText {
    display: inline-block;
    flex: 1 1 auto;
    padding-left: 10px;
}

.companies {
    padding: 10px 0;

    @include mq(md) {
        display: grid;
        grid-column-gap: 50px;
        grid-row-gap: 50px;
    }

    .hasMultipleCompanies & {
        @include mq(md) {
            grid-template-columns: repeat(2, 1fr);
            padding: 25px 0;
            width: 655px;
        }
    }

    .hasThreeOrMoreCompanies & {
        @include mq(md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include mq(lg) {
            grid-template-columns: repeat(3, 1fr);
            width: 1020px;
        }
    }
}

.company {
    &:nth-child(n + 2) {
        margin-top: 45px;
    }

    @include mq(md) {
        flex: 0 0 290px;

        &:nth-child(n + 2) {
            margin-top: 0;
        }
    }

    @include mq(lg) {
        &:nth-child(3) {
            margin-top: 0;
        }

        &:nth-child(3n + 1) {
            margin-left: 0;
        }
    }
}

.vatNumber {
    color: var(--color-grey-60);
    font-size: rem(14);
    font-weight: bold;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.accounts {
    list-style: none;
    margin: 0 -5px;
    padding: 0;
}

.account {
    margin: 15px 0 0;
    padding: 0;
}

.accountButton {
    @include clean-button;
    @include border-radius(sm);
    align-items: center;
    display: flex;
    padding: 6px 5px;
    transition: 0.15s ease-out;
    transition-property: background-color, color;
    width: 100%;

    &:hover {
        background: var(--color-grey-5);
    }

    &.active {
        &,
        &:hover {
            background: var(--color-grey-80);
            color: var(--color-white);
        }
    }
}

.accountName {
    flex: 1 1 100%;
    font-weight: 600;
    text-align: left;
}

.accountNumber {
    flex: 0 0 auto;
    margin-left: 10px;
    text-align: right;
    white-space: nowrap;

    .accountLabel {
        color: var(--color-grey-40);
        margin-right: rem(5);

        .active & {
            color: inherit;
        }
    }
}

.changingCustomerOverlay {
    align-items: center;
    animation: fadeIn 0.4s ease-out;
    backdrop-filter: blur(2px);
    display: flex;
    inset: 0;
    justify-content: center;
    position: absolute;

    &::before {
        background: var(--color-white);
        content: "";
        inset: 0;
        opacity: 0.75;
        position: absolute;
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        z-index: 1;
    }

    .text {
        background: var(--color-white);
        border-radius: 10px;
        box-shadow: 0 0 10px 10px var(--color-white);
        font-weight: 600;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
