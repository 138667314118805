@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    @include border-radius(md);
    @include box-shadow(1);
    background-color: var(--color-white);
    padding: 25px 15px 0;

    @include mq(md) {
        padding: rem(25);
    }
}

.header {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: rem(15);
    justify-content: space-between;
    margin-bottom: rem(15);
}

.mobileWrapper {
    padding-bottom: 15px;
    padding-top: 15px;

    .header {
        margin-bottom: 0;
    }

    .total {
        font-weight: bold;
    }

    .mobileCheckoutListItems {
        &:first-of-type {
            border-top: none;
        }

        &:last-of-type {
            border-bottom: 1px solid var(--color-grey-20);
        }

        padding: rem(10) 0;
    }
}

.mobileBasketDetailsInner {
    & > * {
        margin-top: rem(30);
    }

    &.isCollapsed {
        display: none;
    }

    border-top: 1px solid var(--color-grey-20);
    margin-top: 15px;

    section {
        margin-top: 0;
    }
}

.mobileTotalCollapse {
    div {
        border: none;
        box-shadow: none;
        padding: 0;
    }
}

.editBasket {
    display: flex;
    flex-direction: row;
    margin-bottom: -40px;
    margin-left: auto;
    margin-top: 10px;
    padding: 0;
    width: auto;
}
