@import "@danishagro/shared/src/styles/helpers/all";

.miniCart {
    background: var(--color-white);
    cursor: default;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 10px 0;
    user-select: none;
    width: 100%;

    @include mq(md) {
        @include border-radius(md);
        @include box-shadow(3);
        max-width: rem(335);
        overflow: hidden;
        position: absolute;
        right: 0;
        z-index: 2;
    }
}

.content {
    flex: 1 1 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 15px 15px;
}

.header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 0 0 rem(15);

    @include mq(md) {
        align-items: center;
    }
}

.title {
    font-size: rem(14);
    margin: 0;
}

.account {
    flex: 0 0 auto;
}

.accountMobile {
    margin-bottom: rem(10);
}

.table {
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.head {
    border-bottom: 1px solid var(--color-grey-20);
    border-top: 1px solid var(--color-grey-20);

    th {
        padding: 5px 0 5px 5px;
        text-align: left;

        &:nth-child(n + 2) {
            padding-left: 10px;
            text-align: right;
        }
    }
}

.body {
    td {
        font-size: rem(12);
        padding: 10px 0 0 5px;

        &:first-child {
            padding-left: 0;
        }

        &:nth-child(2) {
            cursor: text;
            user-select: text;
        }

        &:nth-child(n + 3) {
            padding-left: 10px;
            text-align: right;
        }
    }
}

.imageCell {
    width: 30px;
}

.noWrap {
    white-space: nowrap;
}

.image {
    display: block;
    height: 30px;
    object-fit: contain;
    width: 30px;
}

.productName {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.buttonWrapper {
    flex: 0 0 auto;
    padding: 15px 15px 0;
}

.button {
    width: 100%;
}

.noProductsText {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 15px;
}
