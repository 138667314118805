@import "@danishagro/shared/src/styles/helpers/all";

.link {
    @include link-style();
    align-items: center;

    &.largeText {
        font-size: rem(16);
    }
}

.iconWrapper {
    display: inline-block;
    height: 1.3em;
    vertical-align: top;
    width: var(--icon-wrapper-width, 1.3em);
}

.text {
    .underline & {
        text-decoration: underline;
    }

    &:nth-child(n + 2) {
        margin-left: var(--link-text-margin, 0.5em);
    }

    &.bold {
        font-weight: bold;
    }
}
