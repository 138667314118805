@import "@danishagro/shared/src/styles/helpers/all";

.buttonGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: rem(20);

    @include mq(md) {
        flex-direction: row;
    }
}

.modalButton {
    flex: 1;
}

.modalContent {
    position: relative;
}
