@import "@danishagro/shared/src/styles/helpers/all";

.deliveryContainer {
    padding: 10px;
}

.linkbox {
    background-color: var(--color-grey-10);
    margin-bottom: 20px;
    padding: 20px;
}

.linkTag {
    display: flex;
    margin-bottom: 20px;
}

.links {
    display: block;

    &:last-of-type {
        a {
            margin-bottom: 0;
        }
    }
}
