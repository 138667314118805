@import "@danishagro/shared/src/styles/helpers/all";

.modalWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.textWrapper {
    text-align: center;
}
