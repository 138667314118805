@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    background: var(--color-white);
}

.item {
    padding: 15px;
    position: relative;

    @include mq(xs-max) {
        background-color: var(--color-grey-5);
        border: none;
    }

    @include mq(sm) {
        display: flex;
        min-height: 95px;
        padding: 5px 25px 5px 0;
    }
}

.imageWrapper {
    flex: 0 0 60px;
    height: 60px;
    text-align: center;
    width: 60px;

    @include mq(sm) {
        flex-basis: 125px;
        height: auto;
        padding: 0 20px;
        width: 125px;
    }
}

.imageLink {
    display: inline-block;
}

.image {
    height: auto;
    max-height: 60px;
    vertical-align: bottom;
    width: auto;

    @include mq(sm) {
        max-height: none;
    }
}

.numberAndName {
    flex: 1 1 auto;
}

.name {
    font-size: rem(12);

    @include mq(sm) {
        font-size: rem(14);
    }
}

.nameLink {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.actions {
    align-items: center;
    display: flex;
    gap: 10px;

    @include mq(sm) {
        flex: 0 0 35%;
        order: 3;
    }
}

.total {
    font-weight: bold;
    margin: 0 0 0 auto;
    text-align: right;

    @include mq(sm) {
        font-weight: 700;
    }
}

.charges {
    color: var(--color-grey-60);
    font-size: rem(10);
    margin-top: 2px;
}

.deliveryDate {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: rem(12);
    gap: 10px;
    margin-top: 5px;

    @include mq(sm) {
        align-self: center;
        flex: 0 0 25%;
        flex-direction: row;
        font-size: rem(14);
        margin: 0;
        order: 2;
        padding-right: 10px;
    }
}

.dateLabel {
    @include mq(sm) {
        display: none;
    }
}

.showDateLabel {
    color: var(--color-grey-60);
    display: flex;
    font-size: rem(12);
    margin-right: -5px;
}

.hideDateLabel {
    display: none;
}

.amountInfo {
    font-weight: 700;
}

.priceDataSmall {
    color: var(--color-grey-60);
    font-size: rem(12);
}

.mainInfoAlt {
    display: flex;
    flex-direction: row;
    gap: rem(15);

    .deliveryDate {
        display: flex;
        flex-direction: inherit;

        .priceDataSmall {
            font-size: rem(11);
        }
    }

    .mainInfoAltContent {
        display: block;
        width: 100%;
    }

    .totalsWrapper {
        display: flex;
        margin-top: 10px;
    }
}

.mainInfoAltItem {
    background-color: var(--color-white);
    padding-left: 0;
    padding-right: 0;
}
