@import "@danishagro/shared/src/styles/helpers/all";

.overview {
    @include border-radius(md);
    @include box-shadow(1);
    background: var(--color-white);
    padding: 25px 15px;

    @include mq(md) {
        padding: 25px 25px;
    }

    dl {
        display: grid;
        grid-row-gap: 0;
        grid-template-columns: 1fr auto;
        margin: 0;
    }

    dt {
        padding-bottom: 5px;
        padding-right: 10px;
    }

    dd {
        margin: 0;
        padding-bottom: 5px;
        text-align: right;
    }

    .borderBottom {
        border-bottom: 1px solid var(--color-grey-20);

        + div,
        + dt,
        + dt + dd {
            padding-top: 5px;
        }
    }

    .totalExclVat {
        font-weight: 600;
    }

    .total {
        border-bottom: 2px solid var(--color-grey-20);
        border-top: 2px solid var(--color-grey-20);
        font-weight: bold;
        margin-top: 5px;
        padding: 8px 0;
    }

    .comment {
        font-size: rem(10);
        grid-column: 1 / span 2;
        padding-top: 5px;
    }
}

.actions {
    margin-bottom: -10px;
    margin-top: 25px;
    text-align: right;

    @include mq(xs) {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    /*
    .guest {
        margin-top: 15px;

        @include mq(xs) {
            margin: 0;
            order: 1;
        }
    }
     */

    .user {
        @include mq(xs) {
            order: 2;
        }
    }

    > :only-child {
        margin: 0 0 0 auto;
    }
}

.description {
    line-height: rem(18);
    margin-top: 15px;
}

.noPriceButtonWrapper {
    margin-top: 25px;
    text-align: left;

    @include mq(xs) {
        margin-top: 0;
        text-align: right;
    }
}

.roadTax {
    font-size: rem(12);
    font-weight: 400;
    position: relative;
    top: -1px;
}
