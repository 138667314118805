@import "@danishagro/shared/src/styles/helpers/all";

.desktopFooter {
    background-color: var(--color-grey-10);
    box-shadow: 0 1px 3px 0 rgba(#000, 0.1) inset;
    display: block;
    padding: rem(35) 0 calc($toolbar-height + env(safe-area-inset-bottom));

    // Styling for print
    @media print {
        display: none;
    }
}

.desktopFooterInner {
    display: flex;
    margin: 0 auto;
    max-width: 1500px;
}

.desktopFooterColumn {
    flex: 0 0 25%;
    padding-right: rem(25);

    @include mq(md) {
        padding-right: rem(50);
    }
}

.desktopFooterItem {
    margin-bottom: rem(20);

    &.noMarginBottom {
        margin-bottom: 0;
    }
}

.title {
    display: inline-block;
    width: 100%;

    @include mq(md) {
        &:not(:only-child) {
            max-width: 120px;
        }
    }
}

.noWrap {
    white-space: nowrap;
}

.desktopFooterItemTitle {
    color: var(--color-grey-90);
    display: block;
    font-size: rem(14);
    font-weight: 700;
    margin-bottom: rem(15);
    text-transform: initial;
}

.footerItemWrapper {
    margin-bottom: rem(10);

    a {
        color: var(--color-grey-80);
        text-decoration: none;
    }
}

.footerLink {
    @include clean-button;
    color: var(--color-grey-80);
    font-size: rem(14);
    font-weight: 400;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.soMeLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.soMeLink {
    color: var(--color-grey-90);
    display: block;
    height: 35px;
    transition: color 0.1s ease-out;
    width: 35px;
    will-change: color;

    &:hover {
        color: var(--color-action);
    }
}

.addressInfoWrapper {
    position: relative;
}

.addressInfo {
    display: block;
    margin-bottom: 2px;
}
