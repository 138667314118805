@import "@danishagro/shared/src/styles/helpers/all";

.columnList {
    list-style: none;
    padding: 0;
}

.columnItem {
    margin: 10px 0;
}

.columnLink {
    color: var(--color-grey-90);
    text-decoration: none;

    &.active,
    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.showAllLink {
    @include link-style();
}
