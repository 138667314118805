@import "@danishagro/shared/src/styles/helpers/all";

.link {
    color: inherit;
    display: flex;
    gap: 10px;
    margin: 0 -5px;
    padding: 5px;
    text-decoration: none;
}

.data {
    align-items: center;
    align-self: flex-start;
    display: flex;
    flex: 1 1 100%;
    gap: 10px;
}

.details {
    flex: 1 1 100%;
    font-size: rem(14);
}
