@import "@danishagro/shared/src/styles/helpers/all";

.container {
    display: inline-flex;
}

.button {
    flex: 0 0 auto;

    &.minus::before {
        border-radius: var(--border-radius-small) 0 0 var(--border-radius-small) !important;
    }

    &.add::before {
        border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0 !important;
    }

    &:disabled {
        color: var(--color-grey-80);

        &::before {
            background-color: var(--color-grey-20);
        }
    }
}

.input {
    @include border-radius(sm);
    @include remove-number-spin-buttons;
    border: 1px solid var(--color-grey-40);
    color: var(--color-grey-80);
    flex: 0 0 auto;
    font-family: $font-family;
    font-size: 16px;
    margin: 0;
    padding: 0 5px;
    text-align: center;
    width: rem(40);

    &.sharpCorners {
        border-radius: 0;
    }

    &:only-child {
        width: rem(60);
    }

    &.maxValue {
        width: rem(75);
    }

    @include mq(sm) {
        width: rem(40);
    }

    &:focus-visible {
        outline: none;
    }

    &::selection {
        background: var(--color-grey-20);
    }

    &:disabled {
        opacity: 0.5;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        border-color: var(--color-action);
    }
}
