@import "@danishagro/shared/src/styles/helpers/all";

.actions {
    margin: 5px 0 15px;

    @include mq(sm) {
        display: flex;
        justify-content: flex-end;
    }
}

.farmInTimeList {
    margin-top: 10px;

    @include mq(sm) {
        margin-top: 35px;
    }
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 25px;
}
