@import "@danishagro/shared/src/styles/helpers/all";

.charges {
    .chargesInfoItem {
        display: block;
        font-size: 10px;
        font-weight: 400;
        line-height: 150%;
    }
}

.mobileActionWrapper {
    @include border-radius(md);
    background-color: var(--color-grey-5);
    margin-top: 30px;
    padding: 0 10px;

    .mobileActionWrapperItem {
        border-bottom: 1px solid var(--color-grey-10);
        display: block;
        padding: 12px 0;

        &:last-child {
            border: none;
        }
    }

    .deliveryDate {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 0;

        .dateLabel {
            align-items: center;
            display: grid;
            text-align: left;
        }

        .dateShipping {
            font-size: rem(12);
            text-align: right;
        }
    }

    .actionAmountWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .actionAmountWrapperTitle {
            align-items: center;
            display: grid;
            font-size: rem(12);
            text-align: left;
        }

        .actionAmountWrapperInput {
            margin-left: auto;
        }
    }

    .total {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .totalLabel {
            font-size: rem(12);
            text-align: left;
        }

        .totalPrice {
            font-weight: 700;
            text-align: right;
        }
    }
}

.footerDrawerBtns {
    display: flex;
    gap: 10px;

    .button {
        flex: 1;
    }
}

.drawerProductItem {
    .mainInfo {
        display: grid;
        gap: 20px;
        grid-template-columns: 100px 1fr;

        .imageWrapper,
        .imageLink {
            flex: 0 0 100px;
            height: 100px;
            width: 100px;

            img {
                max-height: 100px;
            }
        }
    }

    .numberAndName {
        .name {
            font-size: rem(16);
            font-weight: 700;
        }
    }
}

.image {
    height: auto;
    max-height: 60px;
    vertical-align: bottom;
    width: auto;

    @include mq(sm) {
        max-height: none;
    }
}

.nameLink {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: var(--color-primary-1);
        text-decoration: underline;
    }
}

.number {
    font-size: rem(10);

    @include mq(sm) {
        font-size: rem(12);
    }
}

.driverMessageButtonWrapper {
    margin-top: rem(10);
}

.comment {
    color: var(--color-grey-60);
    font-size: rem(12);
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.extraFeesItem {
    color: var(--color-grey-60);
    display: block;
    font-size: rem(10);
    font-weight: 400;
}

.extraFees {
    position: relative;
    text-align: right;
}

.tag {
    background-color: #fecad3;
    border-radius: 30px;
    color: var(--color-alert);
    font-size: rem(9);
    margin-left: 5px;
    padding: 1px 7px;
    padding-bottom: 2px;

    position: relative;
    top: 1px;

    width: fit-content;
}

.tagItemWeight {
    position: relative;
    top: -2px;
}

.tagTotalWeight {
    margin-left: 0;
}
