@import "@danishagro/shared/src/styles/helpers/all";

.modalMessageContent {
    display: block;
    margin-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;

    @include mq(sm) {
        text-align: left;
    }
}

.closeModalBtn {
    display: block;
    margin: auto;
}
