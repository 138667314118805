@import "@danishagro/shared/src/styles/helpers/all";

.container {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(2, 1fr);

    @include mq(md) {
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq(lg) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mq(xl) {
        grid-template-columns: repeat(4, 1fr);
    }
}
