@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    margin-bottom: 25px;
}

.description {
    margin-bottom: 15px;
}

.addressTitle {
    font-weight: bold;
    margin-bottom: 5px;
}

.addresses {
    max-height: 195px;
    overflow: auto;
}

.address {
    @include border-radius(sm);
    align-items: center;
    border: 1px solid var(--color-grey-10);
    cursor: pointer;
    display: flex;
    gap: rem(10);
    margin-top: 5px;
    padding: rem(10);
    position: relative;

    &:focus-within {
        border-color: var(--color-primary-1);
    }

    &.isChecked {
        background-color: var(--color-primary-5);
    }

    &:first-of-type {
        margin-top: 0;
    }

    span {
        display: block;
    }

    input {
        cursor: inherit;
        inset: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
    }
}

.radioButton {
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-80);
    border-radius: 100%;
    flex-shrink: 0;
    height: rem(16);
    overflow: hidden;
    width: rem(16);

    .isChecked & {
        background-color: var(--color-primary-1);
        border: 0;
        position: relative;
        z-index: 1;

        &::after {
            background-color: var(--color-white);
            border-radius: 100%;
            content: "";
            height: 50%;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
            z-index: 2;
        }
    }
}

.addAddress {
    margin-top: 15px;
}

.addressForm {
    @include border-radius(md);
    background-color: var(--color-grey-10);
    padding: 10px 20px 20px;
}

.street {
    display: flex;
}

.addressDescription {
    flex: 1 1 100%;
    margin-bottom: 15px;
}

.streetName {
    flex: 1 1 100%;
    padding-right: 10px;
}

.streetNumber {
    flex: 0 0 rem(100);
}

.zipCity {
    display: flex;
    margin-top: 15px;
}

.zip {
    flex: 0 0 rem(110);
    padding-right: 10px;
}

.city {
    flex: 1 1 100%;
}

.buttons {
    display: flex;
    gap: 15px;
}

.errorMessage {
    color: var(--color-alert);
    display: block;
    margin-top: rem(10);
}

.inputAddressButton {
    margin-top: 15px;
}
