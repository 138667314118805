@import "@danishagro/shared/src/styles/helpers/all";

.wrapper {
    display: flex;
    margin-right: -10px;

    //Styling for print
    @media print {
        display: none;
    }

    @include mq(sm) {
        padding-top: 15px;
    }
}

.button {
    @include clean-button;
    align-items: center;
    border-left: 1px solid var(--color-grey-5);
    color: var(--color-grey-80);
    display: flex;
    height: rem(46);
    justify-content: center;
    padding: 14px;
    width: rem(50);

    @include mq(sm) {
        border-left: 0;
    }
}

.burgerIcon {
    display: flex;
    flex-direction: column;
    gap: 3px;
    transition: 0.25s ease-in-out;
    width: 100%;

    .isActive & {
        transform: translateX(3px);
    }

    & > span {
        background: currentcolor;
        border-radius: 2px;
        display: block;
        height: 4px;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        width: 20px;

        &:nth-child(1) {
            transform-origin: left center;

            .isActive & {
                transform: rotate(45deg);
            }
        }

        &:nth-child(2) {
            transform-origin: left center;

            .isActive & {
                opacity: 0;
            }
        }

        &:nth-child(3) {
            transform-origin: left center;

            .isActive & {
                transform: rotate(-45deg);
            }
        }
    }
}
